import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchUsers = createAsyncThunk("users/fetchUsers", async (_, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const response = await fetch("http://localhost:3005/users");
        const data = await response.json();
        return data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
});

export const fetchUserById = createAsyncThunk("users/fetchUserById", async (id, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const response = await fetch(`http://localhost:3005/users/${id}`);
        const data = await response.json();
        return data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (id, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
         await fetch(`http://localhost:3005/users/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
                "Accept-Language": "en"

            },
        });
        // const data = await response.json();
        return id;
    } catch (err) {
        return rejectWithValue(err.message);
    }
});

export const createUser = createAsyncThunk("users/createUser", async (user, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const response = await fetch(`http://localhost:3005/users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });
        const data = await response.json();
        return data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
});

export const updateUser = createAsyncThunk("users/updateUser", async (user, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
        const response = await fetch(`http://localhost:3005/users/${user.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });
        const data = await response.json();
        return data;
    } catch (err) {
        return rejectWithValue(err.message);
    }
});


const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    userForEdit: null,
    isLoading: false,
    error: null,
};
const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: {
        // get users
        [fetchUsers.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [fetchUsers.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.entities = action.payload;
            state.totalCount = action.payload.length;
        },
        [fetchUsers.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        // delete user
        [deleteUser.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.entities = state.entities.filter((el) => el.id !== action.payload);
        },
        [deleteUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        // create user
        [createUser.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.entities.unshift(action.payload);
        },
        [createUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // fetch user by id

        [fetchUserById.pending]: (state, action) => {
            state.userForEdit = [];
            state.isLoading = true;
            state.error = null;
        },
        [fetchUserById.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.userForEdit = action.payload;
        },
        [fetchUserById.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // update user
        [updateUser.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.entities = state.entities.map((el) => {
                if (el.id === action.payload.id) {
                    return action.payload;
                }
                return el;
            });
        },
        [updateUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export default userSlice.reducer;