import React, { useState } from "react";
import { useSubheader } from "../../../../../_metronic/layout";
import Invoicing from "./Invoicing";
import Payout from "./Payout";

const TransactionManagement = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Billing Management");
  const [key, setKey] = useState(1);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card-header pb-0 pt-2 border-0">
          <ul
            className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
            data-remember-tab="tab_id"
            role="tablist"
          >
            <li className="nav-item">
              <a
                style={{ cursor: "pointer" }}
                className={`nav-link font-size-h5 ${
                  key === 1 ? "active font-weight-bolder" : ""
                }`}
                data-toggle="tab"
                onClick={() => {
                  setKey(1);
                }}
              >
                Accounts receivable
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ cursor: "pointer" }}
                className={`nav-link font-size-h5 ${
                  key === 2 ? "active font-weight-bolder" : ""
                }`}
                data-toggle="tab"
                onClick={() => {
                  setKey(2);
                }}
              >
                Accounts payable
              </a>
            </li>
          </ul>
        </div>
        {key === 1 && (
          <div className="card card-custom Store-sticky">
            <Invoicing />
          </div>
        )}
        {key === 2 && (
          <div className="card card-custom Store-sticky">
            <Payout />
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionManagement;
