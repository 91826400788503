import React, { useEffect } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Modal, Button, Spinner } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import * as columnFormatters from "../customers-table/column-formatters/StatusColumnFormatter";
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConvertToDateCurrentTimeZoneOnlyDate, Currency, getFromatdate, Number } from "../../../../../../_metronic/_helpers/GlobalHelpers";
import { useTranslation } from "react-i18next";
import { DropdownCustomToggler } from "../../../../../../_metronic/_partials/dropdowns";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import ModalImage from "react-modal-image";

const PharmacyOrdersTable = (props) => {
  const { t } = useTranslation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [isLoading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  // grt id from useParam
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const [initFilter, setinitFilter] = useState({
    page: 1,
    sizePerPage: localStorage.getItem("invoicePageSize") ? parseInt(localStorage.getItem("invoicePageSize")) : 10,
    sortField: "Id",
    sortOrder: "desc",
    totalSize: 10,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 }
    ],
  });

  const columns = [
    {
      dataField: "code",
      text: t("Order#"),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      // redirect to order details page on click
      formatter: (cellContent, row) => (
        <p
          className="text-info  font-weight-bolder mb-0 font-size-lg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/order/${row.id}/details`);
          }}
        >
          #{row.code}
        </p>
      ),
    },
    {
      dataField: "firstName",
      text: t("Customer"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          <>
            <p
              className="text-info  font-weight-bolder mb-0 font-size-lg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/customer/${row.patientId}/details`);
              }}
            >
              {row.firstName} {row.lastName}
            </p>

          </>
        </>
      ),
      headerSortingClasses,
    },
    {
      dataField: "deliveryCity",
      text: t("Delivery"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <OverlayTrigger
          key={"right"}
          placement={"right"}
          overlay={
            <Tooltip id={`tooltip-${"placement"}`}>
              {row.deliveryAddress1}, {row.deliveryAddress2}, {row.deliveryCity}
              , {row.deliveryState}, {row.deliveryZipCode}
            </Tooltip>
          }
        >
          <span
            style={{ cursor: "pointer" }}
            className="svg-icon menu-icon svg-icon-info"
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
          </span>
        </OverlayTrigger>
      ),
      headerSortingClasses,
    },
    {
      dataField: "carrier",
      text: t("Carrier"),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "pickupAppointmentDate",
      text: t("Pickup Date"),
      headerFormatter: (cellContent, row) => (
        <>
          {t("Pickup Date")}
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>{getFromatdate()}</Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        // format date to dd/mm/yyyy
        <p>
          {!!row.pickupAppointmentDate
            ? ConvertToDateCurrentTimeZoneOnlyDate(row.pickupAppointmentDate)
            : "-"}
        </p>
      ),
      headerSortingClasses,
    },
    {
      dataField: "deliveryAppointmentDate",
      text: t("Delivery Date"),
      headerFormatter: (cellContent, row) => (
        <>
          {t("Delivery Date")}
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>{getFromatdate()}</Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        // format date to dd/mm/yyyy
        <p>
          {row.deliveryAppointmentDate
            ? ConvertToDateCurrentTimeZoneOnlyDate(row.deliveryAppointmentDate)
            : ""}
        </p>
      ),
      headerSortingClasses,
    },
    {
      dataField: "distance",
      text: t("Distance"),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <>{row.distance && row.unit ? Number(row.distance) + " " + row.unit : ""}</>
      ),
    },
    {
      dataField: "charge",
      text: t("Charge ($)"),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => <>{row.charge ? Currency (row.charge) : "$0.00"}</>,
      headerSortingClasses,
    },
    {
      dataField: "isHot",
      text: "Priority",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) =>
        row.isHot === true ? (
          <span className="svg-icon svg-icon-3x svg-icon-primary ml-1">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/priority.svg")} />
          </span>
        ) : (
          ""
        ),
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: t("Status"),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    ((JSON.parse(localStorage.getItem("user"))?.roles[0] === "Business Admin" ||
      JSON.parse(localStorage.getItem("user"))?.roles[0] === "Super Admin" ||
      JSON.parse(localStorage.getItem("user"))?.email === "atahir+cp@adelphatech.com") && {
      dataField: "action",
      text: t("Actions"),
      formatter: (cellContent, row) => (
        <div className="text-center">
          {JSON.parse(localStorage.getItem("user"))?.roles[0] === "Business Admin" ||
            JSON.parse(localStorage.getItem("user"))?.roles[0] === "Super Admin" ||
            JSON.parse(localStorage.getItem("user"))?.email === "atahir+cp@adelphatech.com" ?
            <Dropdown
              className="dropdown-inline position-unset text-right"
              alignRight
            >
              <Dropdown.Toggle
                as={DropdownCustomToggler}
                id="dropdown-toggle-top"
              >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <ul className="navi navi-hover py-5">
                  {/* <li className="navi-item">
                <a
                  href={() => false}
                  onClick={() => {
                    setShowModalEdit(true)
                  }}
                  className="navi-link align-items-end "
                >
                  <h3
                    style={{ cursor: "pointer" }}
                    className={`svg-icon svg-icon-md svg-icon-info mr-2 mb-0`}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </h3>
                  <h3 className="navi-text text-hover-info mb-0">
                    {t("Edit order")}
                  </h3>
                </a>
              </li> */}
                  <li className="navi-item">
                    <a
                      href={() => false}
                      onClick={() => {
                        console.log(gmtOffset)
                        setShowModal(true)
                        setOrderId(row?.id)
                      }}
                      className="navi-link"
                    >
                      {/* <span
                    style={{ cursor: "pointer" }}
                    className={`svg-icon svg-icon-md svg-icon-danger mr-1`}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Trash.svg"
                      )}
                    />
                  </span> */}
                      <h3 className="navi-text">{t("Mark as delivered")}</h3>
                    </a>
                  </li>
                  <li className="navi-item">
                    <a
                      href={() => false}
                      onClick={() => {
                        setShowModalRTS(true)
                        setOrderId(row?.id)
                      }}
                      className="navi-link"
                    >
                      {/* <span
                    style={{ cursor: "pointer" }}
                    className={`svg-icon svg-icon-md svg-icon-info mr-1`}
                  >
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
                    />
                  </span> */}
                      <h3 className="navi-text text-bold">
                        {t("Mark as RTS")}
                      </h3>
                    </a>
                  </li>
                  {/* <li className="navi-item">
                <a
                  href={() => false}
                  onClick={() => {
                    handleActivatePharmacy({
                      id: row.id,
                      isActive: !row.isActive,
                    });
                  }}
                  className="navi-link"
                >
                  <span
                    style={{ cursor: "pointer" }}
                    className={`svg-icon svg-icon-md svg-icon-info mr-1`}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Update.svg"
                      )}
                    />
                  </span>
                  <h3 className="navi-text">
                    {row.isActive ? t("Deactivate") : t("Activate")}
                  </h3>
                </a>
              </li>*/}
                  <li className="navi-item">
                    <a
                      href={() => false}
                      onClick={() => {
                        setShowModalAtt(true)
                        setOrderId(row?.id)
                      }}
                      className="navi-link"
                    >
                      {/* <span
                    style={{ cursor: "pointer" }}
                    className={`svg-icon svg-icon-md svg-icon-success mr-1`}
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Design/Substract.svg"
                      )}
                    />
                  </span> */}
                      <h3 className="navi-text text-bold">
                        {t("Add attachment")}
                      </h3>
                    </a>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            : ""}

        </div>
      ),
    }),
  ];

  useEffect(() => {
    setIsLoadingAuth(true);
    setinitFilter({
      page: 1,
      sizePerPage: localStorage.getItem("invoicePageSize") ? parseInt(localStorage.getItem("invoicePageSize")) : 10,
      sortField: "Id",
      sortOrder: "desc",
      custom: true,
      totalSize: 100,
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
    });
  }, []);

  const GetHandlerTableChange = (type, newState) => {
    let values = { ...initFilter };
    if (type === "sort") {
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
    }
    if (type === "pagination") {
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
    }
    setinitFilter(values);
  };

  const firstLetterUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  
  const handleGetOrders = (filter) => {
    filter.pageSize = localStorage.getItem("invoicePageSize") ? parseInt(localStorage.getItem("invoicePageSize")) : 10
    fetch(
      `${BASE_URL}/Orders?pharmacyId=${id}&pageNumber=${filter?.page
      }&pageSize=${filter?.sizePerPage}&search=${props?.search ||
      ""}&sortOrder=${filter?.sortOrder}&sortField=${firstLetterUppercase(
        filter?.sortField
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setLoading(false);
          setOrders(data[1]?.orders);
          props.setPagination(data[1]?.pagination);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      if (initFilter || props?.search) {
        handleGetOrders(initFilter);
      } else {
        handleGetOrders(initFilter);
      }
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [initFilter, props?.search]);

  const paginationOptions = {
    custom: true,
    totalSize: props?.pagination?.totalItemCount || 0,
    sizePerPageList: initFilter?.sizePerPageList || [],
    sizePerPage: props?.pagination?.pageSize || 10,
    page: props?.pagination?.pageNumber || 1,
  };
  ////////////////////////////////////////////////////////////////////////
  const [ShowModal, setShowModal] = useState(false);
  const [LoudingNot, setLoudingNot] = useState(false);
  const [OrderId, setOrderId] = useState(null);
  const [Notes, setNotes] = useState(null);
  useEffect(() => {
    if (!ShowModal) {
      setNotes(null)
    }
  }, [ShowModal])

  const [ShowModalRTS, setShowModalRTS] = useState(false);
  const [LoudingReason, setLoudingReason] = useState(false);
  const [Reason, setReason] = useState(null);

  const [ShowModalatt, setShowModalAtt] = useState(false);
  const [LoudingAtt, setLoudingAtt] = useState(false);
  const [type, settype] = useState(null);
  useEffect(() => {
    console.log("in")

    if (!ShowModalRTS) {
      console.log("in")
      setReason(null)
    }
  }, [ShowModalRTS])
  useEffect(() => {
    if (!ShowModalatt) {
      settype(null)
      const inputElement = document.getElementById('att-upload')
      if (inputElement) {
        inputElement.value = ''
      }
      setImagePreview(null)
      setSelectedFile(null)
    }
  }, [ShowModalatt])
  // const [ShowModalEdit, setShowModalEdit] = useState(false);
  const types = [
    { value: 1, label: "Pickup" },
    { value: 2, label: "Pod" },
    { value: 3, label: "Signature" },
  ];
  function getCurrentGMTOffset() {
    // Get the current date and time
    const currentDate = new Date();
    console.log(currentDate);
    // Get the time zone offset in minutes
    const timeZoneOffsetInMinutes = currentDate.getTimezoneOffset();

    // Calculate the GMT offset in hours and include the sign
    const gmtOffset = -timeZoneOffsetInMinutes / 60;

    return gmtOffset;
  }
  const [gmtOffset, setGmtOffset] = useState(null);

  useEffect(() => {
    const offset = getCurrentGMTOffset();
    setGmtOffset(offset);
  }, []);
  const handleMarkAsDelivered = () => {
    setLoudingNot(true)
    fetch(`${BASE_URL}/Orders/MarkAsDelivered`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "POST",
      body: JSON.stringify({
        id: OrderId,
        value: 0,
        timezone: gmtOffset,
        notes: Notes,
        signedBy: null
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: t("Success"),
            text: t("Order has been marked as Delivered successfully"),
          });
          setShowModal(false)
          setLoudingNot(false)
          if (initFilter || props?.search) {
            handleGetOrders(initFilter);
          } else {
            handleGetOrders(initFilter);
          }
          // handleGetPharmacies(customersUIProps.queryParams);
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
          setLoudingNot(false)

        } else if (data[0] === 401) {
          history.push("/error/401");
          setLoudingNot(false)

        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: t("You have to subscribe first!"),
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
          setLoudingNot(false)

        }
      })
      .catch((error) => {
        setLoudingNot(false)
        console.log(error);
      });
  };
  const handleMarkAsRTS = () => {
    setLoudingReason(true)
    fetch(`${BASE_URL}/Orders/MarkAsRts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "POST",
      body: JSON.stringify({
        id: OrderId,
        timezone: gmtOffset,
        rtsReason: Reason,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: t("Success"),
            text: t("Order has been marked as RTS successfully"),
          });
          if (initFilter || props?.search) {
            handleGetOrders(initFilter);
          } else {
            handleGetOrders(initFilter);
          }
          setShowModalRTS(false)
          setLoudingReason(false)
          // handleGetPharmacies(customersUIProps.queryParams);
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
          setLoudingReason(false)
        } else if (data[0] === 401) {
          history.push("/error/401");
          setLoudingReason(false)

        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: t("You have to subscribe first!"),
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
          setLoudingReason(false)

        }
      })
      .catch((error) => {
        setLoudingReason(false)
        console.log(error);
      });
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      // Display a preview of the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleUpload = async () => {
    setLoudingAtt(true)
    if (!selectedFile) {
      setResponseMessage('Please select an image file.');

    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    fetch(`${BASE_URL}/Orders/Attachments/${OrderId}?type=${type}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      Allow: "POST",
      body: formData,
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: t("Success"),
            text: t("Attachment has been added successfully"),
          });
          setShowModalAtt(false)
          setLoudingAtt(false)
          if (initFilter || props?.search) {
            handleGetOrders(initFilter);
          } else {
            handleGetOrders(initFilter);
          }
          // handleGetPharmacies(customersUIProps.queryParams);
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
          setLoudingAtt(false)

        } else if (data[0] === 401) {
          history.push("/error/401");
          setLoudingAtt(false)

        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: t("You have to subscribe first!"),
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
          setLoudingAtt(false)

        }
      })
      .catch((error) => {
        setLoudingAtt(false)
        console.log(error);
      });

  };
  return (
    <>
      <Modal
        size="lg"
        show={ShowModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Mark as delivered")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label>
              {t("Note")}
              {/* <span className="text-danger">*</span> */}
            </label>
            <input
              type="text"
              className="form-control"
              name="Note"
              placeholder="Note"
              value={Notes}
              onChange={(e) => {
                setNotes(e.target.value);
                // handleSubmit();
              }}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              Swal.fire({
                title: t("Are you sure?"),
                text: t("You want to mark this order as delivered!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#013C73",
                cancelButtonColor: "#d33",
                cancelButtonText: "No",
                confirmButtonText: "Yes",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleMarkAsDelivered();
                }
              });
            }}
          >
            {t("Save")}{LoudingNot && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={ShowModalRTS}
        onHide={() => {
          setShowModalRTS(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Mark as RTS")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label>
              {t("Reason")}
               <span className="text-danger">*</span> 
            </label>
            <input
              type="text"
              className="form-control"
              name="Reason"
              placeholder="Reason"
              value={Reason}
              onChange={(e) => {
                setReason(e.target.value);
                // handleSubmit();
              }}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModalRTS(false);
            }}
          >
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            disabled={LoudingReason}
            onClick={() => {
              Swal.fire({
                title: t("Are you sure?"),
                text: t("You want to mark this order as RTS!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#013C73",
                cancelButtonColor: "#d33",
                cancelButtonText: "No",
                confirmButtonText: "Yes",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  handleMarkAsRTS();
                }
              });
            }}
          >
            {t("Save ")}{LoudingReason && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={ShowModalatt}
        onHide={() => {
          setShowModalAtt(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Add attachment")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label>
              {t("Type")}
              <span className="text-primary">*</span>
            </label>
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              value={types.find(obj =>
                String(obj.value).toLocaleLowerCase().trim() === String(type).toLocaleLowerCase().trim())}
              isSearchable={true}
              name="state"
              options={types}
              onChange={(e) => {
                settype(e.value);
              }}
            />
            <div className='pt-5  d-flex'>
              <label>
                {t("Add attachment ")}
                <span className="text-primary">*</span>
              </label>
              {!!selectedFile === false ?
                <img className="h-20px ml-6 cursor-pointer"
                  onClick={() => { document.getElementById("att-upload").click() }}
                  src={toAbsoluteUrl("/media/icons/add.png")} alt="drag" /> : ""}
            </div>
            <input type="file" id="att-upload" accept="image/*" hidden={true} onChange={handleFileChange} />
            {!!selectedFile === true ?

              <>
                <div className=' d-flex justify-content-center'>
                  <div className='w-150px h-150'>
                    <ModalImage
                      small={toAbsoluteUrl(`/media/icons/img.png`)}
                      large={toAbsoluteUrl(`${imagePreview}`)}
                      alt='Attachment'
                    />
                  </div>
                  <div>
                    <img
                      className=' w-10px h-10px cursor-pointer'
                      src={toAbsoluteUrl(`/media/icons/close.png`)}
                      onClick={() => {
                        const inputElement = document.getElementById('att-upload')
                        if (inputElement) {
                          inputElement.value = ''
                        }
                        setImagePreview(null)
                        setSelectedFile(null)
                      }}
                      alt='File'
                    /></div>
                </div>
              </>
              : ""}

          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModalAtt(false);
            }}
          >
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            disabled={LoudingAtt === true || !!selectedFile === false || !!type === false ? true : false}
            onClick={() => {
              handleUpload();
            }}
          >
            {t("Save")}{LoudingAtt && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <OrderEditDialog
        show={ShowModalEdit}
        id={OrderId}
        onHide={() => {
          history.push(`/store/${id}/details`);
        }}
      /> */}
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps} table="invoicePageSize">
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="orderId"
                data={orders || []}
                columns={columns}
                defaultSorted={[{ dataField: "Created", order: "desc" }]}
                onTableChange={GetHandlerTableChange}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={orders || []} />
                <NoRecordsFoundMessage entities={orders || []} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default PharmacyOrdersTable;
