import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";

export function StaffAssignPharmacyDialogHeader() {
  // Customers Redux state
  const [title, setTitle] = useState("Assign pharmacy");


  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
