import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import QRCODE from "./QRCodeStep";
import AccountVerification from "./AccountVerification";
import BackupVerificationCode from "./BackupVerificationCode";
import { login } from "../../_redux/authCrud";
import { connect } from "react-redux";
import useAuthStore from "./authStore";
import * as auth from "../../_redux/authRedux";
import { FormattedMessage, injectIntl } from "react-intl";

function SetUp(props) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [steps, setsteps] = useState(0);
    const { t } = useTranslation();
    const [code, setcode] = useState(null)
    const [ShowMessag, setShowMessag] = useState(false);
    const { loginData } = useAuthStore();
    const handlePutVerification = () => {
        fetch(`${BASE_URL}/Account/TwoFactorAuthentication/Validation?pinCode=${code}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + loginData?.data?.data?.token,
            },
            Allow: "GET",
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    if (data[1]?.valid) {
                        setsteps(steps + 1)
                    } else {
                        setShowMessag(true)
                    }
                } else if (data[0] === 401) {
                    props.history.push("/error/401");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        if (loginData === null) {
            props.history.push("/auth/login");
        }
    }, [loginData])
    return (
        <div>
            <div className="d-flex flex-column ">
                <div className="container"
                    style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}

                >
                    <div className="col-xl-9 col-lg-9 col-md-12 mx-auto">
                        {steps === 0 ?
                            <>
                                <div className="text-center"
                                >
                                    <h1>How Would You Like to Secure Your Account?</h1>
                                    <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                                        Choose how you'll confirm it's you when you log in on the web.
                                    </div>
                                </div>
                                <div className="d-flex border p-3 w-auto rounded justify-content-between px-5">
                                    <div className=" p-4">
                                        <h1 >Authentication app</h1>
                                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3  fs-3">
                                            Enter a one-time code from an app like Google Authenticator, Authy, Duo or Microsoft Authenticator
                                        </div>
                                    </div>
                                    <div className=" p-4">
                                        <button
                                            // id="kt_login_signin_submit"
                                            onClick={() => setsteps(steps + 1)}
                                            type="button"
                                            className={`btn btn-outline-primary font-weight-bold px-9 py-4 my-3 `}

                                        >
                                            <span>{t("Security app")}</span>
                                            {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                                        </button>
                                    </div>
                                </div>
                            </> : <></>}
                        {steps === 1 ?
                            <QRCODE token={loginData?.data?.data?.token} />
                            : <></>}
                        {steps === 2 ?
                            <AccountVerification setcode={setcode} ShowMessag={ShowMessag} />
                            : <></>}
                        {steps === 3 ?
                            <BackupVerificationCode token={loginData?.data?.data?.token} />
                            : <></>}

                    </div>
                </div>
                <div className="d-flex justify-content-between w-100 h-20 px-10 py-5 mt-auto position-fixed bottom-0">
                    <div>
                        <button className="btn btn-light mr-3 " onClick={() => {
                            console.log("in")
                            props.history.push("/auth/twoFactor")
                        }}>Cancel</button>
                    </div>
                    <div>

                        {steps === 3 ?
                            <button disabled={steps === 0 ? true : false} onClick={() => {
                                props.login(loginData?.data?.accessToken)
                            }} className="btn btn-primary ">Done</button> :
                            <button disabled={steps === 0 ? true : false} onClick={() => {
                                if (steps === 2) {
                                    handlePutVerification()
                                } else {
                                    setsteps(steps + 1)
                                }
                            }} className="btn btn-primary ">Next</button>
                        }
                    </div>
                </div>
            </div>

        </div>

    );
}
export default injectIntl(connect(null, auth.actions)(SetUp));

