import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useSubheader } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";
import { TagsInput } from "react-tag-input-component";
import ReactQuill from 'react-quill';
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';

const ProductSheama = Yup.object().shape({
    name: Yup.string().required(("Product name is required")),
    CategoryIds: Yup.array().min(1, "You have to choose one of these categories").required("You have to choose one of these categories"),
    price: Yup.string().required(("price is required")),
});
const ProductOTCEvent = () => {
    const history = useHistory();
    const [initialValues, setinitialValues] = useState({
        name: "",
        description: "",
        price: "",
        CategoryIds: "",
        availableQuantity: 0,
        expiryDate: null,
        tags: [],
        manufacturer: "",
        isActive: true
    });
    const [PharmacyId, setPharmacyId] = useState(null);

    const GetPharmacyId = () => {
        fetch(`${BASE_URL}/Stores/products/status`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setPharmacyId(data[1]?.pharmacyId)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(()=>{
        GetPharmacyId()
    },[])
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = JSON.parse(localStorage.getItem("user")).token;
    const suhbeader = useSubheader();
    const [categories, setcategories] = useState([]);
    const [isLoading, setisLoading] = useState({
        Api1: false,
        Api2: false,
        Api3: false,
    });
    suhbeader.setTitle();
    const status = [
        { value: true, label: "Active" },
        { value: false, label: "Inactive" },
    ];
    const [Product, setProduct] = useState(null);

    useEffect(() => {
        if(!!PharmacyId === true){

        const url = window.location.pathname; // Get the current URL
        const extractNumberFromUrl = (url) => {
            const urlParts = url.split("/");
            const numberFromUrl = urlParts[urlParts.length - 1];
            return parseInt(numberFromUrl, 10);
        };
        const number = extractNumberFromUrl(url);
        if (!!number) {
            getProduct(number)
        }
    }
    }, [PharmacyId]);
    suhbeader.setTitle(!!Product === false ? "Create a new product" : "Edit product");
    const [isLoadingCategory, setisLoadingCategory] = useState(false);
    const [image2, setImage2] = useState();
    const [CategoryName, setCategoryName] = useState();
    const [showCategory, setShowCategory] = useState(false);
    const [showEdite, setshowEdite] = useState(false);
    const handleCloseCategory = () => setShowCategory(false);
    const handleShowCategory = () => setShowCategory(true);
    const [Messageerror, setMessageerror] = useState(false);
    useEffect(() => {
        if (!showCategory) {
            setCategoryName(null);
            setImage2(null);
            setshowEdite(false);
        }
    }, [showCategory])
    const HandlPostCategory = () => {
        setisLoadingCategory(true)

        const formData = new FormData();
        formData.append('files', image2?.file);
        formData.append('name', CategoryName);
        fetch(`${BASE_URL}/Stores/product/category`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${token}`,
            },
            Allow: "POST",
            body: formData,
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setisLoadingCategory(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        // text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                        text: `Category added Successfully`,
                    });
                    handleCloseCategory()
                    getcategories()
                } else if (data[0] === 400 && data[1].message) {
                    setisLoadingCategory(false)

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: data[1].message,
                    });
                    
                } else if (data[0] === 500) {
                    setisLoadingCategory(false)

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoadingCategory(false)

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const handleImageChange2 = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const imageObject = {
                    file: file,
                    result: e.target.result,
                };
                // Update the image state with the new object
                setImage2(imageObject);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleImageRemove2 = () => {
        setImage(null);
    };




    const [images, setImages] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const handleImageUpload = (event) => {
        event.preventDefault();
    
        let files;
        if (event.dataTransfer) {
            // Handle drag-and-drop
            files = event.dataTransfer.files;
        } else if (event.target.files) {
            // Handle file input
            files = event.target.files;
        }
    
        if (files) {
            const newImages = [...images];
    
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onload = (e) => {
                    const imageObject = {
                        file: file,
                        result: e.target.result,
                    };
    
                    newImages.push(imageObject);
    
                    if (i === files.length - 1) {
                        // If last file, update the state
                        setImages(newImages);
                    }
                };
    
                reader.readAsDataURL(file);
            }
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        setIsHovered(true);
    };
    const handleDragLeave = () => {
        setIsHovered(false);
    };
    const handleRemoveImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
    };
    const [image, setImage] = useState();

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const imageObject = {
                    file: file,
                    result: e.target.result,
                };
                console.log(imageObject);
                // Update the image state with the new object
                setImage(imageObject);
            };

            reader.readAsDataURL(file);
        }
    };
    const getProduct = (id) => {
        fetch(`${BASE_URL}/Stores/products/${id}/${PharmacyId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setProduct(data[1]);
                    const arrayOfIds = data[1]?.categories.map(category => category.id);
                    setinitialValues({
                        name: data[1]?.name,
                        description: data[1]?.description,
                        price: data[1]?.price,
                        CategoryIds: arrayOfIds,
                        availableQuantity: data[1]?.availableQuantity,
                        expiryDate: null,
                        tags: data[1]?.tags,
                        manufacturer: "",
                        isActive: data[1]?.isActive
                    })
                    formik.resetForm();
                    const mainImageObject = data[1]?.images.find(item => item?.isMain === true);
                    console.log(mainImageObject)

                    // Create a new object with "result" and "file" properties
                    const ImageD = {
                        result: mainImageObject ? mainImageObject?.uriImage : null,
                        file: null,
                        id: mainImageObject.id
                    };
                    console.log(mainImageObject,ImageD)
                    setImage(ImageD)
                    const nonMainImageObjects = data[1]?.images.filter(item => item.isMain === false);

                    // Create an array of setImage objects for each non-main image
                    const setImageNonMainArray = nonMainImageObjects?.map(item => ({
                        result: item?.uriImage,
                        file: null,
                        id: item?.id
                    }));
                    setImages(setImageNonMainArray)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const HandlPostImages = (id) => {
        console.log(images)
        const formData = new FormData();

        // Append each image file to the FormData object
        for (let i = 0; i < images?.length; i++) {
            console.log(images[i])
            if (images[i]?.file !== null) {
                formData.append('files', images[i]?.file);
            }
        }
        fetch(`${BASE_URL}/Stores/products/images/${id}/false`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${token}`,
            },
            Allow: "POST",
            body: formData,
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    if (!!Product === true) {
                        if (!!image?.id === true) {
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }else if (!!image?.id === false && isLoading?.Api2 ===false){
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }
                    } else {
                        if (!!image === false) {
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }else if (!!image === false && isLoading?.Api2 ===false){
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }
                    }

                    setisLoading(prevState => ({ ...prevState, Api3: false }));
                } else if (data[0] === 400 && data[1].errors) {
                    console.log(data[1].errors);
                    setisLoading(prevState => ({ ...prevState, Api3: false }));
                    for (var i = 0; i < data[1].errors.length; i++) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: data[1].errors[i].description,
                        });
                    }
                } else if (data[0] === 500) {
                    setisLoading(prevState => ({ ...prevState, Api3: false }));
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoading(prevState => ({ ...prevState, Api3: false }));
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const HandlDeleteImages = (id, imageID, index,isMain) => {

        fetch(`${BASE_URL}/Stores/products/images/${id}/${imageID}`, {
            method: "DELETE",
            headers: {
                Authorization: `bearer ${token}`,
            }
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    if(isMain === false){
                        handleRemoveImage(index)
                    }else{
                        setImage(null);
                    }
                    
                }
            })
            .catch((error) => {
                setisLoading(prevState => ({ ...prevState, Api3: false }));
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const HandlPostMainImage = (id) => {
        console.log(images)
        const formData = new FormData();
        formData.append('files', image?.file);


        fetch(`${BASE_URL}/Stores/products/images/${id}/true`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${token}`,
            },
            Allow: "POST",
            body: formData,
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    if(!!Product === true){
                        const hasObjectWithoutId = !images.every(obj => obj.id !== undefined);

                        if(hasObjectWithoutId === false ){
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }else if(hasObjectWithoutId === true && isLoading?.Api2 === false){
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }
                    }else{
                        if(images.length ===0 ){
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }else if(images.length> 0 && isLoading?.Api2 === false){
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                            });
                            history.push("/Storefront");
                        }
                    }
                   
                    setisLoading(prevState => ({ ...prevState, Api2: false }));
                } else if (data[0] === 400 && data[1].errors) {
                    setisLoading(prevState => ({ ...prevState, Api2: false }));
                    console.log(data[1].errors);
                    for (var i = 0; i < data[1].errors.length; i++) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: data[1].errors[i].description,
                        });
                    }
                } else if (data[0] === 500) {
                    setisLoading(prevState => ({ ...prevState, Api2: false }));
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoading(prevState => ({ ...prevState, Api2: false }));
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const handleImageRemove = () => {
        if(Product){
            Swal.fire({
                title: "Info!",
                text: ("You want to remove this image!"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    HandlDeleteImages(Product?.id , image?.id ,0,true)
                }
            });
        }else{
            setImage(null);
        }
    };
    const HandlPostproduct = (values) => {
        fetch(`${BASE_URL}/Stores/products`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            Allow: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    if (images?.length !== 0) {
                        HandlPostImages(data[1])
                    } else {
                        setisLoading(prevState => ({ ...prevState, Api3: false }));
                    }
                    if (!!image === true) {
                        HandlPostMainImage(data[1])
                    } else {
                        setisLoading(prevState => ({ ...prevState, Api2: false }));
                    }
                    console.log(images,image)
                    console.log(images?.length === 0 && !!image === false)
                    if (images?.length === 0 && !!image === false) {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                        });
                        history.push("/Storefront");
                    }
                    setisLoading(prevState => ({ ...prevState, Api1: false }));
                    console.log({ ...isLoading, Api1: false })


                } else if (data[0] === 400 && data[1].message) {
                    setisLoading({
                        Api1: false,
                        Api2: false,
                        Api3: false,
                    })
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: data[1].message,
                    });
                } else if (data[0] === 400) {
                    setisLoading({
                        Api1: false,
                        Api2: false,
                        Api3: false,
                    })
                    let tt = JSON.parse(data[1]);
                    for (const [key, value] of Object.entries(tt.errors)) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: value,
                        });
                    }
                }
            })
            .catch((error) => {
                setisLoading({
                    Api1: false,
                    Api2: false,
                    Api3: false,
                })
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const HandlEditproduct = (values) => {
        fetch(`${BASE_URL}/Stores/products/${Product?.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            Allow: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    const hasObjectWithoutId = !images.every(obj => obj.id !== undefined);
                    console.log(hasObjectWithoutId)
                    if (hasObjectWithoutId === true && images?.length !== 0) {
                        HandlPostImages(!!Product === true ? Product?.id : data[1])
                    } else {
                        setisLoading(prevState => ({ ...prevState, Api3: false }));
                    }
                    if (!!image?.id === false && !!image?.result === true) {
                        HandlPostMainImage(!!Product === true ? Product?.id : data[1])
                    } else {
                        setisLoading(prevState => ({ ...prevState, Api2: false }));
                    }
                    console.log(hasObjectWithoutId,image)
                    if (hasObjectWithoutId === false && (!!image?.id === true || image=== undefined )) {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                        });
                        history.push("/Storefront");
                    }
                    setisLoading(prevState => ({ ...prevState, Api1: false }));


                } else if (data[0] === 400 && data[1].message) {
                    setisLoading({
                        Api1: false,
                        Api2: false,
                        Api3: false,
                    })
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: data[1].message,
                    });
                } else if (data[0] === 400) {
                    setisLoading({
                        Api1: false,
                        Api2: false,
                        Api3: false,
                    })
                    let tt = JSON.parse(data[1]);
                    for (const [key, value] of Object.entries(tt.errors)) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: value,
                        });
                    }
                }
            })
            .catch((error) => {
                setisLoading({
                    Api1: false,
                    Api2: false,
                    Api3: false,
                })
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const getcategories = () => {
        fetch(`${BASE_URL}/Stores/product/category`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setcategories(data[1]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getcategories()
    }, [])
    const formik = useFormik({
        initialValues,
        validationSchema: ProductSheama,
        onSubmit: (values) => {
            setisLoading({
                Api1: true,
                Api2: true,
                Api3: true,
            })
            if (!!Product === true) {
                HandlEditproduct(values)
            } else {
                HandlPostproduct(values)
            }
        },
    });
    return (
        <div>
            <div className="d-flex justify-content-end mb-5">
                {/*begin::Button*/}
                <Link
                    to="/Storefront"
                    className="btn btn-secondary mr-5"
                >
                    Cancel
                </Link>
                {/*end::Button*/}
                {/*begin::Button*/}
                <button
                    type="button"
                    disabled={isLoading?.Api1 === true || isLoading?.Api2 === true || isLoading?.Api3 === true ? true : false}
                    className="btn btn-primary mr-5"
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                >  Save changes
                    <span className="">
                        {(isLoading?.Api1 === true ||
                            isLoading?.Api2 === true ||
                            isLoading?.Api3 === true) ?
                            <span className="spinner spinner-white ml-2 p-4">
                            </span> : ""}

                    </span>

                    {/* <span className="indicator-progress">
                        Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ml-2" />
                    </span> */}
                </button>
                {/*end::Button*/}
            </div>
            <form
                id="kt_ecommerce_add_product_form"
                className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
                data-kt-redirect="/metronic8/demo1/../demo1/apps/ecommerce/catalog/products.html"
            >

                {/*begin::Aside column*/}
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 mr-lg-10">
                    {/*begin::Thumbnail settings*/}
                    <div className="card  py-4 mb-3">
                        {/*begin::Card header*/}
                        <div >
                            {/*begin::Card title*/}
                            <div className="p-10">
                                <h2>Thumbnail</h2>
                            </div>
                            {/*end::Card title*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body text-center pt-0">
                            <div
                                className="image-input image-input-outline"
                                id="kt_profile_avatar"
                                style={{
                                    backgroundImage: `url('${image?.result}')`,
                                    backgroundSize: 'cover', 
                                    backgroundPosition: 'center', 
                                }}
                            >
                                <div
                                    className="image-input-wrapper"
                                    style={{
                                        backgroundImage: `url('${image?.result}')`,
                                        backgroundSize: 'cover', 
                                        backgroundPosition: 'center', 
                                    }}
                                />
                                <label
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="change"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Change avatar"
                                >
                                    <i className="fa fa-pen icon-sm text-muted"></i>
                                    <input
                                        type="file"
                                        id="profile_avatar"
                                        name="profile_avatar"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleImageChange}
                                    />
                                    <input type="hidden" name="profile_avatar_remove" />
                                </label>
                                <span
                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="remove"
                                    data-toggle="tooltip"
                                    title=""
                                    data-original-title="Remove avatar"
                                    onClick={handleImageRemove}
                                >
                                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                            </div>
                            <span className="form-text text-muted">
                                Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted
                            </span>
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Thumbnail settings*/}
                    {/*begin::Status*/}
                    <div
                        className="card py-4 mb-3"
                    >
                        {/*begin::Card header*/}
                        <div className="p-5">
                            {/*begin::Card title*/}
                            <div className="">
                                <h2>Status</h2>
                            </div>
                            {/*end::Card title*/}
                            {/*begin::Card toolbar*/}

                            {/*begin::Card toolbar*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0" data-select2-id="select2-data-145-7qa1">

                            <ReactSelect
                                className="basic-single mb-2"
                                classNamePrefix="select"
                                defaultValue={status[0]}
                                isSearchable={true}
                                name="color"
                                options={status}
                                value={status?.find(option => option?.value === formik.values.isActive)}
                                onChange={(e) => {
                                    formik.setFieldValue("isActive", e?.value);
                                  }}
                            />
                            <div className="text-muted fs-7">Set the product status.</div>

                            <div className="d-none mt-10">
                                <label
                                    htmlFor="kt_ecommerce_add_product_status_datepicker"
                                    className="form-label"
                                >
                                    Select publishing date and time
                                </label>
                                <input
                                    className="form-control flatpickr-input"
                                    id="kt_ecommerce_add_product_status_datepicker"
                                    placeholder="Pick date & time"
                                    type="text"
                                    readOnly="readonly"
                                />
                            </div>
                            {/*end::Datepicker*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Status*/}
                    {/*begin::Category & tags*/}
                    <div
                        className="card card-flush py-4"
                        data-select2-id="select2-data-131-2aha"
                    >
                        {/*begin::Card header*/}
                        <div className="">
                            {/*begin::Card title*/}
                            <div className="p-5">
                                <h2>Product Details</h2>
                            </div>
                            {/*end::Card title*/}
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0" data-select2-id="select2-data-130-3tol">
                            {/*begin::Input group*/}
                            {/*begin::Label*/}
                            {/* <label className="form-label">Categories<span className="text-primary">*</span></label>
                            <ReactSelect
                                className="basic-single mb-2"
                                classNamePrefix="select"
                                defaultValue={status[0]}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={Categories}
                            />
                            <div className="text-muted fs-7 mb-7">Add product to a category.</div>
                            {/*end::Description*/}
                            {/*end::Input group*/}
                            {/*begin::Button*/}
                            {/* <a
                                href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/add-category.html"
                                className="btn btn-light-primary btn-sm mb-10"
                            >
                                <i class="ki ki-plus icon-sm"></i> Create new category
                            </a>  */}
                            {/*end::Button*/}
                            {/*begin::Input group*/}
                            {/*begin::Label*/}
                            <label className="form-label d-block">Tags</label>
                            <TagsInput
                                value={formik.values.tags === null ? []:formik.values.tags}
                                onChange={(value) => {
                                    formik.setFieldValue("tags", value)
                                }}
                                name="AuthorizedPerson"
                                placeHolder={("Add tags")}

                            />
                            <div className="text-muted fs-7">Add tags to a product.</div>
                            {/*end::Description*/}
                            {/*end::Input group*/}
                        </div>
                        {/*end::Card body*/}
                    </div>

                </div>
                {/*end::Aside column*/}
                {/*begin::Main column*/}
                <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                    <div>
                        {/*begin::Tab pane*/}
                        <div
                            className="tab-pane fade show active"
                            id="kt_ecommerce_add_product_general"
                            role="tab-panel"
                        >
                            <div className="d-flex flex-column gap-7 gap-lg-10">
                                {/*begin::General options*/}
                                <div className="card card-flush py-4 mb-5">
                                    {/*begin::Card header*/}
                                    <div className=" p-10">
                                        <div className="card-title">
                                            <h2>General</h2>
                                        </div>
                                    </div>
                                    {/*end::Card header*/}
                                    {/*begin::Card body*/}
                                    <div className="card-body pt-0">
                                        {/*begin::Input group*/}
                                        <div className="mb-10 fv-row fv-plugins-icon-container">
                                            {/*begin::Label*/}
                                            <label className=" form-label">Product Name <span className="text-primary">* </span></label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input
                                                type="text"
                                                name="product_name"
                                                className="form-control mb-2"
                                                placeholder="Product name"
                                                value={formik.values.name}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "name",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block text-primary">
                                                        * {formik.errors.name}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/*end::Input*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fs-7">
                                                A product name is required and recommended to be unique.
                                            </div>
                                            {/*end::Description*/}
                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                                        </div>
                                        {/*end::Input group*/}
                                        {/*begin::Input group*/}
                                        <div className="h-200px">
                                            {/*begin::Label*/}
                                            <label className="form-label">Description</label>
                                            {/*end::Label*/}
                                            {/*begin::Editor*/}
                                            <ReactQuill
                                                value={formik.values.description}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    formik.setFieldValue("description", value);
                                                }}
                                                modules={{ /* customize modules */ }}
                                                formats={{ /* customize formats */ }}
                                                style={{ position: 'relative', height: '150px' /* Set the desired height for the editor itself */ }}

                                            />
                                            {/*end::Editor*/}
                                            {/*begin::Description*/}

                                            {/*end::Description*/}
                                        </div>
                                        <div className="text-muted fs-7 mt-10">
                                            Set a description to the product for better visibility.
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-5 mb-5">
                                                <h6 className="form-label mt-3">Categories<span className="text-primary">*</span></h6>
                                                {/* <a
                                                    href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/add-category.html"
                                                    className="btn btn-light-primary btn-sm mb-10"
                                                >
                                                    <i class="ki ki-plus icon-sm"></i> Create new category
                                                </a> */}
                                                   <button
                                                   type="button"
                                                     className="btn btn-light-primary btn-bold mr-3"
                                                     onClick={() => {
                                                         handleShowCategory()
                                                         // setcategorie(null, search)
                                                         // setDataProducs([])
                                                         // getProducts(null, search, pageNumber)
                                                     }}
                                                 >
                                                     <i class="ki ki-plus icon-nm"></i>
                                                     Create new category
                                                 </button>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center ">
                                                <ul
                                                    className="nav nav-pills d-flex  nav-pills-custom gap-3 "
                                                    role="tablist"
                                                >
                                                    {/*begin::Item*/}
                                                    {categories?.map((category) => {
                                                        return (
                                                            <li className="nav-item  mr-0 text-center" role="presentation"
                                                            onClick={() => {
                                                                console.log("in");
                                                                const currentCategoryId = category?.id;
                                                              
                                                                // Check if category?.id is already in formik.values.categoryId
                                                                if (formik.values.CategoryIds.includes(currentCategoryId)) {
                                                                  // Remove category?.id from formik.values.categoryId
                                                                  formik.setFieldValue(
                                                                    "CategoryIds",
                                                                    formik.values.CategoryIds.filter((id) => id !== currentCategoryId)
                                                                  );
                                                                } else {
                                                                  // Add category?.id to formik.values.categoryId
                                                                  formik.setFieldValue("CategoryIds", [...formik.values.CategoryIds, currentCategoryId]);
                                                                }
                                                                console.log(formik.values.CategoryIds)
                                                              }}
                                                            >
                                                                {/*begin::Nav link*/}
                                                                <div
                                                                    className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack  page-bg `}
                                                                    data-bs-toggle="pill"

                                                                    style={{ width: 138, height: 180 }}
                                                                    aria-selected="false"
                                                                    tabIndex={-1}
                                                                    role="tab"
                                                                >
                                                                    {/*begin::Icon*/}
                                                                    {/*begin::Food icon*/}
                                                                    <div className={`border ${formik.values.CategoryIds.includes(category?.id) ? "border-primary" : "border-secondary"} rounded py-5 w-100px h-130px`}>
                                                                        <img
                                                                            src={toAbsoluteUrl(category?.uriImage)}
                                                                            className="w-50px mb-2"
                                                                            alt=""
                                                                        />
                                                                        <div className="">
                                                                            <span className={`text-gray-800 fw-bold fs-2 d-block ${formik.values.CategoryIds.includes(category?.id) ? "text-primary" : ""}`}>
                                                                                {category?.name}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    {/*end::Info*/}
                                                                </div>
                                                                {/*end::Nav link*/}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            {formik.touched.CategoryIds && formik.errors.CategoryIds ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block text-primary">
                                                        * {formik.errors.CategoryIds}
                                                    </div>
                                                </div>
                                            ) : null}
                                    {/*end::Card header*/}
                                </div>

                                <div className="card card-flush py-4 mb-5">
                                    {/*begin::Card header*/}
                                    <div className="">
                                        <div className="p-10">
                                            <h2>Media</h2>
                                        </div>
                                    </div>
                                    {/*end::Card header*/}
                                    {/*begin::Card body*/}
                                    <div className="card-body pt-0">
                                        {/*begin::Input group*/}
                                        <div className="fv-row mb-2">
                                            {/*begin::Dropzone*/}
                                            <div className={`dropzone dropzone-default ${isHovered ? 'dropzone-primary' : 'dropzone-secondary'} dz-clickable cursor-pointer `}
                                                onDrop={handleImageUpload}
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}
                                            >
                                                <input
                                                    type="file"
                                                    id="imageInput"
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                    style={{ display: 'none' }}
                                                    multiple // Allow multiple file selection
                                                />
                                                <label htmlFor="imageInput">
                                                    <div className="d-flex align-items-center cursor-pointer">
                                                        <i class="flaticon-upload icon-4x mr-10"></i>
                                                        <div className="dropzone-msg dz-message needsclick cursor-pointer">
                                                            <h2 className="dropzone-msg-title">Drop picture here or click to upload.</h2>
                                                        </div>
                                                    </div>
                                                </label>
                                                {images?.length > 0 && (
                                                    <div className="d-flex justify-content-center flex-wrap">  
                                                        {images?.map((image, index) => (
                                                            <div key={index} className="ml-5 mt-5">  
                                                                <div className="d-flex justify-content-center w-100">
                                                                    <div className="d-flex justify-content-end mb-1 cursor-pointer w-100px">
                                                                        <img
                                                                            className=' w-10px h-10px cursor-pointer scale-hover'
                                                                            src={toAbsoluteUrl(`/media/icons/close.png`)}
                                                                            onClick={() => {
                                                                                console.log(image?.id, image)
                                                                                if (!!Product === true) {
                                                                                    if (!!image?.id === true) {
                                                                                        Swal.fire({
                                                                                            title: "Info!",
                                                                                            text: ("You want to remove this image!"),
                                                                                            icon: "warning",
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: "Yes",
                                                                                            reverseButtons: true,
                                                                                        }).then((result) => {
                                                                                            if (result.isConfirmed) {
                                                                                                HandlDeleteImages(Product?.id, image?.id, index,false)
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        handleRemoveImage(index)
                                                                                    }
                                                                                } else {
                                                                                    handleRemoveImage(index)
                                                                                }
                                                                                // setImage(null)
                                                                            }}
                                                                            alt='File'
                                                                        /></div>
                                                                </div>
                                                                <img src={toAbsoluteUrl(image?.result)}
                                                                    className="rounded h-100px "
                                                                    alt="Uploaded" />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            {/*end::Dropzone*/}
                                        </div>
                                        <div className="text-muted fs-7">
                                            Set the product media gallery.
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-flush py-4">
                                    {/*begin::Card header*/}
                                    <div className="">
                                        <div className="p-10">
                                            <h2>Pricing</h2>
                                        </div>
                                    </div>
                                    {/*end::Card header*/}
                                    {/*begin::Card body*/}
                                    <div className="card-body pt-0">
                                        {/*begin::Input group*/}
                                        <div className="mb-10 fv-row fv-plugins-icon-container">
                                            {/*begin::Label*/}
                                            <label className="required form-label">Base Price<span className="text-primary">*</span></label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input
                                                type="number"
                                                name="price"
                                                className="form-control mb-2"
                                                placeholder="Product price"
                                                defaultValue=""
                                                value={formik.values.price}
                                                onChange={(e) => {
                                                    const inputValue = parseFloat(e.target.value);

                                                    // Round the value to two decimal places
                                                    const roundedValue = !isNaN(inputValue) ? parseFloat(inputValue.toFixed(2)) : '';

                                                    formik.setFieldValue("price", roundedValue);
                                                }}
                                            />
                                            {formik.touched.price && formik.errors.price ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block text-primary">
                                                        * {formik.errors.price}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {/*end::Input*/}
                                            {/*begin::Description*/}
                                            <div className="text-muted fs-7">Set the product price.</div>
                                            {/*end::Description*/}
                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                                        </div>
                                    </div>
                                    {/*end::Card header*/}
                                </div>
                                {/*end::Pricing*/}
                            </div>
                        </div>
                    </div>
                    {/*end::Tab content*/}
                </div>
               </div>
                {/*end::Main column*/}
            </form>
            <Modal show={showCategory}
                onHide={handleCloseCategory}
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title> {(`${showEdite === true?"Update":"Create"} category`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="  py-4 mb-3">
                            {/*begin::Card header*/}
                            <div >
                                {/*begin::Card title*/}
                                <div className="p-10">
                                    <h4>Category picture</h4>
                                </div>
                                {/*end::Card title*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className=" text-center pt-0">
                                <div
                                    className="image-input image-input-outline"
                                    id="kt_profile_avatar"
                                    style={{
                                        backgroundImage: `url('${image2?.result}')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    <div
                                        className="image-input-wrapper"
                                        style={{
                                            backgroundImage: `url('${image2?.result}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    />
                                    <label
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="change"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Change avatar"
                                    >
                                        <i className="fa fa-pen icon-sm text-muted"></i>
                                        <input
                                            type="file"
                                            id="profile_avatar"
                                            name="profile_avatar"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={handleImageChange2}
                                        />
                                        <input type="hidden" name="profile_avatar_remove" />
                                    </label>
                                    <span
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="remove"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Remove avatar"
                                        onClick={handleImageRemove2}
                                    >
                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                </div>
                                <span className="form-text text-muted">
                                    Set the category thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted
                                </span>
                            </div>
                            {/*end::Card body*/}
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Category name <span class="text-danger">*</span></label>
                            <input type="text" value={CategoryName} class="form-control" id="exampleInputPassword1" placeholder="Category name"
                                onChange={(e) => {
                                    setCategoryName(e.target.value);
                                }} />
                                {Messageerror === true && !!CategoryName === false?<span class="text-danger mt-3 ml-2">Category name is required</span>:""}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="d-flex justify-content-end"
                    >
                        <button
                            type="button"
                            onClick={handleCloseCategory}
                            className="btn btn-light btn-elevate"
                        >
                            {("Cancel")}
                        </button>
                        <button
                            type="button"
                            disabled={isLoadingCategory}
                            onClick={()=>{
                                
                                if(!!CategoryName === false){
                                    setMessageerror(true)
                                }else{
                                    HandlPostCategory()
                                    setMessageerror(false)
                                }
                            }}
                            className="btn btn-primary btn-elevate ml-3"
                        >
                            {(`${showEdite === true?"Update":"Create"}`)}{isLoadingCategory === true?<span className="spinner spinner-white ml-2 p-4">
                            </span>:""}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
};
export default ProductOTCEvent;
