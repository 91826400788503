import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactCodeInput from 'react-verification-code-input'

export default function AccountVerification({ setcode , ShowMessag }) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const { t } = useTranslation();
    const handleCodeChange = (code) => {
        setcode(code) // Handle the code value here
    }

    return (
        <div className="px-5  ">
            <div className="h-100">
                <div className="px-60">
                    <div className="text-center">
                        <h1>Verify Authentication App</h1>
                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                            Enter a code from your security or authentication app to make sure everything works.
                        </div>

                    </div>
                    <div className="d-flex justify-content-center ">
                        <div>
                            <label className="font-weight-bolder">6-digit code</label>
                            <ReactCodeInput type='number' fields={6} onChange={handleCodeChange} />
                            {ShowMessag === true ?
                                <div className="fv-plugins-message-container mt-3">
                                    <div className="fv-help-block text-primary">
                                        This doesn't look like the right code
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
