import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { ConvertToDateCurrentTimeZoneOnlyDate, Currency } from '../../../../../../_metronic/_helpers/GlobalHelpers';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import { ModelStateMessage } from '../../../../../../_metronic/_helpers/apiHelpers';

const CreditMemos = ({
    showCreditMemo,
    setShowCreditMemo,
    setHidden,
    selectedCreditMemo,
    setSelectedCreditMemo,
    setCreditMemoNumber,
    creditMemoCodeNumbers,
    setTotalSelectedCreditMemo,
    totalSelectedCreditMemo,
    startDate,
    endDate,
    invoiceId
}) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = JSON.parse(localStorage.getItem("user")).token;
    const params = useParams();
    const id = params.id || invoiceId;
    const [isFetchingMemos, setIsFetchingMemos] = useState(false);
    const [creditMemos, setCreditMemos] = useState([]);

    function getTimeZoneOffset() {
        const offsetInMinutes = new Date().getTimezoneOffset();
    
        const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const sign = offsetInMinutes < 0 ? "+" : "-";
    
        return `${sign}${hours.toString().padStart(2, "")}`;
      }

    const handleGetCreditMemos = () => {
        setIsFetchingMemos(true);
        fetch(
          `${BASE_URL}/Pharmacies/GetCreditMemo?pharmacyId=${id}&ChargeSuccess=${false}&timezone=${getTimeZoneOffset()}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            Allow: "GET",
          }
        )
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response.text().then((data) => [statusCode, data]);
            }
          })
          .then((data) => {
            if (data[0] === 200) {
              setCreditMemos(data[1]?.orders);
            }
            else {
              ModelStateMessage(data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setIsFetchingMemos(false);
          });
      };
      
      useEffect(() => {
        if(showCreditMemo){
          handleGetCreditMemos();
        }
      }, [showCreditMemo]);

  return (
    <div>
    <Modal
        size="lg"
        show={showCreditMemo}
        onHide={() => {
          setShowCreditMemo(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adjust Your Payment with Credit Memos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="alert alert-custom alert-light-warning mb-15" role="alert">
          Please note: The selected credit memos will be deducted from your total amount due. 
          Ensure you have selected all applicable credit memos to update your total correctly.
        </div>
        {!isFetchingMemos && creditMemos?.map((invoice, index) => (
          <div className="d-flex align-items-center mb-5" key={index}>
          <span className="bullet bullet-bar bg-success align-self-stretch" />
          <label className="checkbox checkbox-lg checkbox-light-success checkbox-inline flex-shrink-0 m-0 mx-4">
            <input 
            type="checkbox" 
            name="select" 
            checked={selectedCreditMemo.includes(invoice.id)}
            onChange={(e) => {
              if(e.target.checked){
                setTotalSelectedCreditMemo(totalSelectedCreditMemo + invoice.totalWithTax)
                setSelectedCreditMemo([...selectedCreditMemo, invoice.id])
                setCreditMemoNumber([...creditMemoCodeNumbers, {
                  totalWithTax: invoice.totalWithTax,
                  invoiceNumber: invoice.invoiceNumber
                }])
              }else{
                setTotalSelectedCreditMemo(totalSelectedCreditMemo - invoice.totalWithTax)
                setSelectedCreditMemo(selectedCreditMemo.filter((item) => item !== invoice.id))
                setCreditMemoNumber(creditMemoCodeNumbers.filter((item) => item.invoiceNumber !== invoice.invoiceNumber))
              }
              }
            } 
            />
            <span />
          </label>
          <div className="d-flex flex-column flex-grow-1">
            <span
              className="text-dark-75 font-weight-bold font-size-lg mb-1"
            >
              Invoice : #{invoice.invoiceNumber}
            </span>
            <span className="text-dark font-weight-bold">
              Created at : <span className="text-muted font-weight-bold">
                {ConvertToDateCurrentTimeZoneOnlyDate(invoice?.created)}
              </span>
            </span>
          </div>
          <span className="text-danger font-weight-bold font-size-lg mb-1">
            {Currency(invoice.totalWithTax)}
          </span>
        </div>
        ))}
        {isFetchingMemos && [1,2,3,4,5,6,7,8].map((item) => (
          <div className="mb-5">
            <Skeleton width={"col-12"} height={50} key={item}/>
            </div>
        ))}
        {!isFetchingMemos && creditMemos.length === 0  && (
                  <div className="d-flex justify-content-center my-20">
                    <span
                      className="text-dark-75 font-weight-bold font-size-lg mb-1"
                    >
                      No credit memos found.
                    </span>
                  </div>
        )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            className="me-5"
            onClick={() => {
              setShowCreditMemo(false);
              setHidden(false);
              setSelectedCreditMemo([])
              setCreditMemoNumber([])
              setTotalSelectedCreditMemo(0)
            }}
          >
             Close & Unselect All
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if(selectedCreditMemo.length === 0){
                Swal.fire({
                  icon: "info",
                  title: "Oops...",
                  text: "Please select at least one credit memo",
                });
                return;
              }else{
                setShowCreditMemo(false);
                setHidden(false);
            }}}
          >
            Apply Credit Memos
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CreditMemos