import firebase from 'firebase';
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_AUTH_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};
firebase.initializeApp(config);
firebase.firestore().settings({
  timestampsInSnapshots: true,
});
let messagingExport = {};
if (firebase.messaging.isSupported()) {
  messagingExport = firebase.messaging();
}
export const messaging = messagingExport;
export const myFirebase = firebase;
export const myFirestore = firebase.firestore();
export const myStorage = firebase.storage();
