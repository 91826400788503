import React from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactSelect from "react-select";
import {
  ModelStateMessage,
  apiCalll,
  creditCardTypeFromNumber,
} from "../../../../../../_metronic/_helpers/apiHelpers";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import { slice } from "lodash";

const MonerisModal = ({
  show,
  setShow,
  setHiddenCard,
  setbilling,
  Billing,
  isPaying,
  pharmacyId,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLaoding, setIsLaoding] = React.useState(false);
  const listMonth = [];
  const listMonthSelect = [];
  const listYear = [];
  const listYearSelect = [];
  var currentTime = new Date().getFullYear();
  for (let index = 1; index <= 12; index++) {
    if (index < 10) {
      listMonth.push(<option>{`0${index}`}</option>);
      listMonthSelect.push(`0${index}`);
    } else {
      listMonth.push(<option>{index}</option>);
      listMonthSelect.push(index);
    }
  }
  for (let index = 0; index <= 20; index++) {
    listYear.push(<option>{currentTime + index}</option>);
    listYearSelect.push(currentTime + index);
  }

  function ImageCardNumber({ value }) {
    return (
      <img
        src={toAbsoluteUrl(
          creditCardTypeFromNumber(value) === "Visa"
            ? "/media/svg/card-logos/visa.svg"
            : creditCardTypeFromNumber(value) === "MasterCard"
            ? "/media/svg/card-logos/mastercard.svg"
            : creditCardTypeFromNumber(value) === "Amex"
            ? "/media/svg/card-logos/american-express.svg"
            : creditCardTypeFromNumber(value) === "Discover"
            ? "/media/svg/card-logos/discover.svg"
            : creditCardTypeFromNumber(value) === "UnionPay"
            ? "/media/svg/card-logos/unionpay.svg"
            : creditCardTypeFromNumber(value) === "JCB"
            ? "/media/svg/card-logos/jcb.svg"
            : creditCardTypeFromNumber(value) === "Diners Club"
            ? "/media/svg/card-logos/diners-club.svg"
            : creditCardTypeFromNumber(value) === "Union Pay"
            ? "/media/svg/card-logos/unionpay.svg"
            : ""
        )}
        alt=""
        className="h-25px"
      />
    );
  }

  function handleSubmit(data) {
    setIsLaoding(true);
    return apiCalll({
      method: "Post",
      link: `/Pharmacies/AddMonerisPharmacyCard`,
      data: data,
    })
      .then((res) => {
        if (res[0] === 200) {
          setbilling({
            ...Billing,
            methodPayment: "moneris",
            stripeCardId: res[1].id,
          });
          document.getElementById("proceed_to_payment").click();
        } else if (res[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        } else {
          ModelStateMessage(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLaoding(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      pharmacyId: parseInt(pharmacyId || id),
      cardName: "",
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCvc: "",
    },
    validationSchema: Yup.object({
      cardName: Yup.string().required("Card Name is required"),
      cardNumber: Yup.string().required("Card Number is required"),
      cardExpiryMonth: Yup.string().required("Card Expiry Month is required"),
      cardExpiryYear: Yup.string().required("Card Expiry Year is required"),
      cardCvc: Yup.string().required("Card CVC is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Moneris Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group fv-plugins-icon-container">
              <label>
                Name on card <span className="text-danger">*</span>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      style={{ pointerEvents: "none" }}
                      id="products-delete-tooltip"
                    >
                      Specify a card holder name
                    </Tooltip>
                  }
                >
                  <i className="fa fa-exclamation-circle ml-2 fs-7"></i>
                </OverlayTrigger>
              </label>
              <input
                type="text"
                className="form-control  form-control-md"
                placeholder="Card Name"
                name="cardName"
                value={formik.values.cardName}
                onChange={formik.handleChange}
              />
              {formik.errors.cardName && formik.touched.cardName ? (
                <div className="fv-plugins-message-container">
                  <div
                    data-field="width"
                    data-validator="digits"
                    className="fv-help-block text-danger"
                  >
                    {formik.errors.cardName}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label>
                Card number
                <span className="text-danger">*</span>
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control  form-control-md"
                  placeholder="Card number"
                  name="cardNumber"
                  maxLength={16}
                  value={formik.values.cardNumber}
                  onChange={formik.handleChange}
                />
                {formik.errors.cardNumber && formik.touched.cardNumber ? (
                  <div className="fv-plugins-message-container">
                    <div
                      data-field="width"
                      data-validator="digits"
                      className="fv-help-block text-danger"
                    >
                      {formik.errors.cardNumber}
                    </div>
                  </div>
                ) : null}
                <div className="position-absolute translate-middle-c top-50 end-0 mr-5">
                  {/* <ImageCardNumber value={4242424242424242} /> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <div className="form-group fv-plugins-icon-container">
                  <label>
                    Expiration date
                    <span className="text-danger">*</span>
                  </label>
                  <ReactSelect
                    name="cardExpiryMonth"
                    isSearchable={true}
                    placeholder="Month"
                    options={listMonthSelect.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    value={
                      formik.values.cardExpiryMonth
                        ? {
                            value: formik.values.cardExpiryMonth,
                            label: formik.values.cardExpiryMonth,
                          }
                        : null
                    }
                    onChange={(value) => {
                      formik.setFieldValue("cardExpiryMonth", value.value);
                    }}
                  />
                  {formik.errors.cardExpiryMonth &&
                  formik.touched.cardExpiryMonth ? (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="width"
                        data-validator="digits"
                        className="fv-help-block text-danger"
                      >
                        {formik.errors.cardExpiryMonth}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-xl-4">
                <div className="form-group fv-plugins-icon-container mt-8">
                  <ReactSelect
                    name="cardExpiryYear"
                    isSearchable={true}
                    placeholder="Year"
                    options={listYearSelect.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    value={
                      formik.values.cardExpiryYear
                        ? {
                            value: formik.values.cardExpiryYear,
                            label: "20" + formik.values.cardExpiryYear,
                          }
                        : null
                    }
                    onChange={(value) => {
                      // take only last 2 digits of year
                      const lastTwoDigits = value.value.toString().slice(-2);
                      formik.setFieldValue("cardExpiryYear", lastTwoDigits);
                    }}
                  />
                  {formik.errors.cardExpiryYear &&
                  formik.touched.cardExpiryYear ? (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="width"
                        data-validator="digits"
                        className="fv-help-block text-danger"
                      >
                        {formik.errors.cardExpiryYear}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/*end::Input*/}
              </div>

              <div className="col-xl-4">
                {/*begin::Input*/}
                <div className="form-group fv-plugins-icon-container">
                  <label>
                    CVV <span className="text-danger">*</span>
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          style={{ pointerEvents: "none" }}
                          id="products-delete-tooltip"
                        >
                          Enter a cvv code
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-exclamation-circle ml-2 fs-7"></i>
                    </OverlayTrigger>
                  </label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder="CVV"
                      name="cardCvc"
                      maxLength={3}
                      value={formik.values.cardCvc}
                      onChange={formik.handleChange}
                    />
                    <div className="position-absolute translate-middle-c top-50 end-0 mr-3">
                      <span className="svg-icon svg-icon-2hx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path d="M22 7H2V11H22V7Z" fill="currentColor" />
                          <path
                            opacity="0.3"
                            d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  {formik.errors.cardCvc && formik.touched.cardCvc ? (
                    <div className="fv-plugins-message-container">
                      <div
                        data-field="width"
                        data-validator="digits"
                        className="fv-help-block text-danger"
                      >
                        {formik.errors.cardCvc}
                      </div>
                    </div>
                  ) : null}
                  <div className="fv-plugins-message-container" />
                </div>
                {/*end::Input*/}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary mr-3"
            onClick={() => {
              setHiddenCard(false);
              setShow(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={isLaoding || isPaying}
          >
            {isLaoding || isPaying ? (
              <div className="d-flex align-items-center">
                Procceding to payment...
                <span className="spinner-border spinner-border-sm ml-3"></span>
              </div>
            ) : (
              "Proceed to payment"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MonerisModal;
