import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useHistory } from "react-router-dom";
import { ExportAs } from "../../../../_metronic/_helpers/GlobalHelpers";
import { Dropdown } from "react-bootstrap";
import ImportGlobal from "../../Tools/ImportGlobal";

const ProductOTC = () => {
    const suhbeader = useSubheader();
    // suhbeader.setTitle("Help Center");
    const history = useHistory();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = JSON.parse(localStorage.getItem("user")).token;
    const [categorie, setcategorie] = useState(null);
    const [DataProducts, setDataProducs] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const handleCloseDetail = () => setShowDetail(false);
    const handleShowDetail = () => setShowDetail(true);
    const [categories, setcategories] = useState([]);
    const [productDetail, setproductDetail] = useState(null);
    const [search, setSearch] = useState("");
    const [pageNumber, setpageNumber] = useState(1);
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingCat, setisLoadingCat] = useState(false);
    const [isLoadingDET, setisLoadingDET] = useState(false);
    const [isLoadingCategory, setisLoadingCategory] = useState(false);
    const [PharmacyId, setPharmacyId] = useState(null);
    const [isLoadingEX, setisLoadingEX] = useState(false);
    const [image, setImage] = useState();
    const [CategoryName, setCategoryName] = useState();
    const [showCategory, setShowCategory] = useState(false);
    const [showEdite, setshowEdite] = useState(false);
    const handleCloseCategory = () => setShowCategory(false);
    const handleShowCategory = () => setShowCategory(true);
    const [Messageerror, setMessageerror] = useState(false);
    const [ShowModal, setShowModal] = useState(false);
    const characterCount = !!CategoryName?.length === true ? CategoryName?.length:0 ;
    const maxCharacters = 50;
    const [pagination, setPagination] = useState({
        count: 0,
        pageCount: 0,
        pageNumber: 1,
        pageSize: 12,
        totalItemCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        isFirstPage: true,
        isLastPage: true,
    });
    const GetPharmacyId = () => {
        fetch(`${BASE_URL}/Stores/products/status`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setPharmacyId(data[1]?.pharmacyId)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        GetPharmacyId()
    }, [])
    const getcategories = () => {
        setisLoadingCat(true)
        fetch(`${BASE_URL}/Stores/product/category`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setisLoadingCat(false)
                    setcategories(data[1]);
                }
            })
            .catch((error) => {
                setisLoadingCat(false)
                console.log(error);
            });
    };
    const handleDeleteProduct = (id) => {
        fetch(`${BASE_URL}/Stores/products/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            Allow: "DELETE",
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    getProducts(categorie, search, pageNumber)
                    Swal.fire({
                        title: "Success!",
                        text: "Product has been deleted successfully!",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                } else if (data[0] === 402) {
                    Swal.fire({
                        title: "Info!",
                        text: "You have to subscribe first!",
                        icon: "info",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push("/user-profile");
                        }
                    });
                } else if (data[0] === 400) {
                    Swal.fire({
                        title: "Info!",
                        text: data[1]?.message,
                        icon: "info",
                        confirmButtonText: "Ok",
                    });
                } else if (data[0] === 500) {
                    setisLoading(prevState => ({ ...prevState, Api3: false }));
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const getProducts = (categoryId, searchProducts, pageNumber) => {
        setisLoading(true)

        fetch(`${BASE_URL}/Stores/products?pharmacyId=${PharmacyId}&categoryId=${categoryId !== null ? categoryId : ""}&search=${searchProducts}&pageNumber=${pageNumber}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setDataProducs(data[1]?.products);
                    setPagination({
                        count: data[1]?.pagination?.count,
                        pageCount: data[1]?.pagination?.pageCount,
                        pageNumber: data[1]?.pagination?.pageNumber,
                        pageSize: data[1]?.pagination?.pageSize,
                        totalItemCount: data[1]?.pagination?.totalItemCount,
                        hasPreviousPage: data[1]?.pagination?.hasPreviousPage,
                        hasNextPage: data[1]?.pagination?.hasNextPage,
                        isFirstPage: data[1]?.pagination?.isFirstPage,
                        isLastPage: data[1]?.pagination?.isLastPage,
                    });
                    setisLoading(false)
                }
            })
            .catch((error) => {
                setisLoading(false)
                console.log(error);
            });
    };
    function Export(Type) {
        setisLoadingEX(true)
        fetch(`${BASE_URL}/Stores/products?pharmacyId=${PharmacyId}&categoryId=${categorie !== null ? categorie : ""}&search=${search}&pageSize=1000000000`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setisLoadingEX(false)
                    var newArray = data[1]?.products?.map(item => ({
                        ProductID: item?.id,
                        ProductName: item?.name,
                        Description: item?.description,
                        CategoriesName: item?.categoriesNames,
                        mainImage: item?.mainImage,
                        Status: item?.isActive,
                        Price: item?.price,
                        Created: new Date(item?.created).toISOString().replace('T', ' ').substring(0, 23),
                        Tags: Array.isArray(item?.tags) ? item?.tags?.join(',  ') : item.tags,
                    }));
                    console.log(newArray)
                    ExportAs(newArray || [], Type, "Pharmacy_Product");
                } else {
                    setisLoadingEX(false)
                }
            })
            .catch((error) => {
                setisLoadingEX(false)
                console.log(error);
            });
    }
    const getProductDetails = (ProductId) => {
        setisLoadingDET(true)
        fetch(`${BASE_URL}/Stores/products/${ProductId}/${PharmacyId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setisLoadingDET(false)
                    setproductDetail(data[1]);
                } else if (data[0] === 400 && data[1].errors) {
                    console.log(data[1].errors);
                    setisLoadingDET(false)
                    for (var i = 0; i < data[1].errors.length; i++) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: data[1].errors[i].description,
                        });
                    }
                } else if (data[0] === 500) {
                    setisLoadingDET(false)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                } else {
                    setisLoadingDET(false)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoadingDET(false)
                console.log(error);
            });
    };
    
    const HandlPostCategory = () => {
        setisLoadingCategory(true)

        const formData = new FormData();
        formData.append('files', image?.file);
        formData.append('name', CategoryName);
        fetch(`${BASE_URL}/Stores/product/category`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${token}`,
            },
            Allow: "POST",
            body: formData,
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setisLoadingCategory(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        // text: `Product ${!!Product === true ?"updated":"added"} Successfully`,
                        text: `Category added Successfully`,
                    });
                    handleCloseCategory()
                    getcategories()
                } else if (data[0] === 400 && data[1].message) {
                    setisLoadingCategory(false)

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: data[1].message,
                    });
                    
                } else if (data[0] === 500) {
                    setisLoadingCategory(false)

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoadingCategory(false)

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const HandlPutCategory = () => {
        setisLoadingCategory(true)
        const formData = new FormData();
        if(image?.file === null){
            formData.append('files', image?.result);
        }else{
            formData.append('files', image?.file);
        }
        formData.append('name', CategoryName);
        fetch(`${BASE_URL}/Stores/product/category/${categorie}`, {
            method: "Put",
            headers: {
                Authorization: `bearer ${token}`,
            },
            Allow: "POST",
            body: formData,
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setisLoadingCategory(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: `Category updated Successfully`,
                    });
                    handleCloseCategory()
                    getcategories()
                }else if (data[0] === 400 && data[1].message) {
                    setisLoadingCategory(false)

                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: data[1].message,
                    });
                    
                }  else if (data[0] === 500) {
                    setisLoadingCategory(false)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoadingCategory(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const HandlDeleteCategory = () => {
        fetch(`${BASE_URL}/Stores/product/category/${categorie}`, {
            method: "DELETE",
            headers: {
                Authorization: `bearer ${token}`,
            }
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    setcategories(null);
                    getcategories()
                    setcategorie(null, search)
                    setDataProducs([])
                    getProducts(null, search, pageNumber)
                    
                    Swal.fire({
                        title: "Success!",
                        text: "Category has been deleted successfully!",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                } else if (data[0] === 402) {
                    Swal.fire({
                        title: "Info!",
                        text: "You have to subscribe first!",
                        icon: "info",
                        confirmButtonText: "Ok",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push("/user-profile");
                        }
                    });
                } else if (data[0] === 400) {
                    Swal.fire({
                        title: "Info!",
                        text: data[1]?.message,
                        icon: "info",
                        confirmButtonText: "Ok",
                    });
                } else if (data[0] === 500) {
                    setisLoading(prevState => ({ ...prevState, Api3: false }));
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };
    const getCategoryDetails = () => {
        fetch(`${BASE_URL}/Stores/product/category/${categorie}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${token}`,
            },
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                } else {
                    return response.text().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    const imageObject = {
                        file: null,
                        result: data[1]?.uriImage,
                    };
setCategoryName(data[1]?.name);
setImage(imageObject);
                } else if (data[0] === 400 && data[1].errors) {
                    for (var i = 0; i < data[1].errors.length; i++) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: data[1].errors[i].description,
                        });
                    }
                } else if (data[0] === 500) {
                    setisLoadingDET(false)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                } else {
                    setisLoadingDET(false)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: ("Something went wrong!. Please try again later"),
                    });
                }
            })
            .catch((error) => {
                setisLoadingDET(false)
                console.log(error);
            });
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const imageObject = {
                    file: file,
                    result: e.target.result,
                };
                // Update the image state with the new object
                setImage(imageObject);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleImageRemove = () => {
        setImage(null);
    };
    useEffect(() => {
        if (!showDetail) {
            setproductDetail(null)
        }
    }, [showDetail])
    useEffect(() => {
        if (!showCategory) {
            setCategoryName(null);
            setImage(null);
            setshowEdite(false);
        }
    }, [showCategory])
    useEffect(() => {
        getcategories()
        setisLoading(true)
        setisLoadingCat(true)
    }, [])
    useEffect(() => {
        if (!!PharmacyId === true) {
            setisLoading(true)
            getProducts(null, "", pageNumber)
        }
    }, [PharmacyId])
    const handlePageChange = (newPageNumber) => {
        setDataProducs(null)
        getProducts(categorie, search, newPageNumber);
    };
    const images = ["/media/icons/productcards.webp", "/media/icons/productcards.webp", "/media/icons/productcards.webp"]
    return (
        <div>
            <div id="kt_app_content" className="app-content  flex-column-fluid ">
                <div className="card mb-5">
                    <div
                        className="card-body flex-column p-5 d-block bgi-no-repeat bgi-size-cover bgi-position-center card-rounded position-relative min-h-150px"
                    >
                        <div className="d-flex justify-content-center align-items-center ">
                            <h1 className="fw-bold fs-4 fs-lg-1 text-gray-800 mt-5 mb-lg-10">
                                Our top Storefront categories
                            </h1>
                        </div>

                        {isLoadingCat && (

                            <div className="col-md-12 mb-5 pb-5">
                                <div className="card-xl-stretch me-md-6">
                                    <div className="m-0">
                                        <div className="fs-6 fw-bold d-flex justify-content-center">
                                            <h1 className="text-muted mx-3 mt-17">
                                                Loading...
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isLoadingEX && (
                            <>
                                <div
                                    className='modal fade show d-block'
                                    id='kt_modal_add_user'
                                    role='dialog'
                                    tabIndex={-1}
                                    aria-modal='true'
                                >
                                    <div className='modal-dialog modal-dialog-centered mw-350px'>
                                        <div className='modal-content'>
                                            <div className='modal-body scroll-y mx-5 mx-xl-5 my-7 text-center'>
                                                <span className="text-dark-75 font-weight-bold fs-2 pb-3">Please wait...</span>
                                                <div className="d-flex justify-content-center pt-3">
                                                    <div
                                                        style={{ width: '3rem', height: '3rem', fontSize: '1.8rem !important' }}
                                                        className="spinner-border text-success" role="status">
                                                        <span
                                                            style={{ fontSize: '1.8rem !important' }}
                                                            className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='modal-backdrop fade show'></div>
                            </>
                        )}
                        <div className="d-flex justify-content-end align-items-center mb-5 mt-5">
                            {!!categorie === true ?
                            <div>
                                 <button
                                className="btn btn-light-info btn-bold mr-3 "
                                onClick={() => {
                                    setshowEdite(true)
                                    handleShowCategory()
                                    getCategoryDetails()
                                }}
                            >
                                 <i class="fas fa-edit icon-nm"></i>
                                Edit
                            </button>
                        <button
                                className="btn btn-light-danger btn-bold mr-3"
                                onClick={() => {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        text: ("You want to delete this category!"),
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#d33",
                          cancelButtonColor: "#3085d6",
                                        confirmButtonText: "Yes",
                                        reverseButtons: true,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            HandlDeleteCategory()
                                        }
                                    });
                                }}
                            >
                                <i class="fas fa-trash icon-nm"></i>
                                Delete
                            </button>

                            </div>
                            :""}
                       
                            <button
                                className="btn btn-light-primary btn-bold mr-3"
                                onClick={() => {
                                    handleShowCategory()
                                    // setcategorie(null, search)
                                    // setDataProducs([])
                                    // getProducts(null, search, pageNumber)
                                }}
                            >
                                <i class="ki ki-plus icon-nm"></i>
                                Create new category
                            </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ul
                                className="nav nav-pills d-flex nav-pills-custom gap-3 h-100"
                                role="tablist"
                            >
                                {/*begin::Item*/}
                                {categories?.map((category) => {
                                    return (
                                        <li className="nav-item  mr-0 text-center" role="presentation h-100"
                                            onClick={() => {
                                                setcategorie(category?.id)
                                                getProducts(category?.id, search, 1)
                                            }}
                                        >
                                            {/*begin::Nav link*/}
                                            <div
                                                className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack  page-bg h-100`}
                                                data-bs-toggle="pill"

                                                style={{ width: 138 }}
                                                aria-selected="false"
                                                tabIndex={-1}
                                                role="tab"
                                            >
                                                {/*begin::Icon*/}
                                                {/*begin::Food icon*/}
                                                <div className={`border ${categorie === category?.id ? "border-primary" : "border-secondary"} rounded py-5 w-100px h-100 `}>
                                                    <img
                                                        src={toAbsoluteUrl(category?.uriImage)}
                                                        className="w-50px mb-2"
                                                        alt=""
                                                    />
                                                    <div className="">
                                                        <span className={`text-gray-800 fw-bold fs-2 d-block ${categorie === category?.id ? "text-primary" : ""}`}>
                                                            {category?.name}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/*end::Info*/}
                                            </div>
                                            {/*end::Nav link*/}
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body p-10 p-lg-10" >
                        <div className="d-flex justify-content-between align-items-center mb-5">
                            <h3 className="text-gray-900">Products</h3>
                        </div>
                        <div className="separator separator-dashed mb-9" />
                        <div className="d-flex justify-content-between flex-wrap mb-10">

                            <div className=" w-50 mt-5" >
                                <i className="ki-outline ki-magnifier fs-2 text-primary position-absolute top-50 translate-middle ms-8" />
                                <input
                                    type="text"
                                    className="form-control fs-4 py-4 ps-14 text-gray-700 placeholder-gray-500  mt-5"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(e) => {
                                        console.log(e.target.value.length)
                                        setSearch(e.target.value)
                                        setpageNumber(1)
                                        getProducts(categorie, e.target.value, 1)
                                    }}
                                />

                            </div>
                            <div className="d-flex flex-wrap mt-5" >
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className="mr-3 mb-xl0 mb-lg-0 mb-md-0 mt-5"
                                        variant="success"
                                        id="dropdown-basic"
                                    >
                                        <span className="svg-icon svg-icon-md svg-icon-white">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} />
                                        </span>
                                        {("Export as")}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                Export("excel");
                                            }}
                                            href={"#"}
                                        >
                                            <i className="fas fa-file-excel mr-3"></i> Excel
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                Export("csv");
                                            }}
                                            href="#"
                                        >
                                            <i className="fas fa-file-csv mr-3"></i> Csv
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                Export("json");
                                            }}
                                            href="#"
                                        >
                                            <i className="fas fa-file mr-3"></i> Json
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button
                                  id="ImportOrders"
                                  type="button"
                                  className="btn btn-success mt-5 mr-3"
                                  onClick={() => setShowModal(true)}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-white">
                                    <SVG
                                      src={toAbsoluteUrl("/media/svg/icons/Files/Import.svg")}
                                    />
                                  </span>
                                  {("Import")}
                                </button>
                                {categorie === null ? "" :
                                    <button
                                        className="btn btn-light-primary btn-bold mr-3 mt-5"
                                        onClick={() => {
                                            setcategorie(null, search)
                                            setDataProducs([])
                                            getProducts(null, search, pageNumber)
                                        }}
                                    >
                                        Show all products
                                    </button>
                                }
                                <Link
                                    to="/new-product"
                                    className="btn btn-info mt-5"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-white">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")}
                                        />
                                    </span>
                                    {("New Product")}
                                </Link>
                            </div>
                        </div>
                        {/*begin::Content*/}
                        {/*begin::Pos food*/}
                        <div className="card card-flush card-p-0 bg-transparent border-0 ">
                            <div className="tab-content">
                                {/*begin::Tap pane*/}
                                <div
                                    className=""
                                >
                                    <div className="d-flex flex-wrap justift-content-center">
                                        {/*begin::Card*/}
                                        {DataProducts?.map((product) => {
                                            const mainImage = product?.images.find(image => image.isMain);
                                            const otherImages = product?.images.filter(image => !image?.isMain);
                                            console.log(otherImages)
                                            return (
                                                <div className=" mb-5 col-xl-4 col-md-12 col-lg-6">
                                                    <div className="p-5 text-center border border-secondary rounded shadow-sm d-flex justify-content-start h-100">
                                                        <div className="mr-3">
                                                            <div>
                                                                { }
                                                                <img
                                                                    src={toAbsoluteUrl(mainImage ? mainImage.uriImage : '/media/icons/no-image.png')}
                                                                    className="rounded mb-4  h-100px h-xxl-150px cursor-pointer"
                                                                    alt=""
                                                                    style={{
                                                                        maxWidth: '100%', // Set maximum width to 100% of the parent div
                                                                    }}
                                                                    onClick={() => {
                                                                        handleShowDetail()
                                                                        getProductDetails(product?.id)
                                                                    }}
                                                                />
                                                                <div className="w-150px h-100px">
                                                                    {otherImages?.length > 0 ?
                                                                        <Slide style={{ position: 'relative', height: '200px', width: '200px' }}>
                                                                            {otherImages?.map((image, index) => {
                                                                                return (
                                                                                    <img
                                                                                        key={index}
                                                                                        src={toAbsoluteUrl(image.uriImage)}
                                                                                        style={{
                                                                                            maxWidth: '100%', // Set maximum width to 100% of the parent div
                                                                                        }}
                                                                                        className="rounded mb-4  h-90px cursor-pointer"
                                                                                        alt=""
                                                                                    />
                                                                                )
                                                                            })}
                                                                        </Slide>
                                                                        : ""}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="mb-2 ml-3">
                                                                <div className=" d-flex">
                                                                    <span className="font-weight-bolder text-gray-800 cursor-pointer text-dark fs-3 fs-xl-1 mb-3 mt-9">
                                                                        {product?.name && product?.name.length > 20
                                                                            ? product?.name.slice(0, 20) + '...'
                                                                            : product?.name}
                                                                    </span>
                                                                </div>
                                                                {product?.categories?.map((category, index, array) => (
                                                                  <span
                                                                    key={category?.id} // Make sure to add a unique key when mapping over an array
                                                                    className="font-weight-bolder text-gray-800 cursor-pointer align-items-start text-muted fs-3 fs-xl-1 d-flex justify-content-start mb-3"
                                                                  >
                                                                    {index < 2 ? category?.name : (index === 2 ? '...' : null)}
                                                                  </span>
                                                                ))}
                                                            </div>
                                                            <h3 className="text-primary text-end font-weight-bolder ml-3  d-flex justify-content-start mt-2 mb-3">${product?.price}</h3>

                                                            <div className="d-flex justify-content-center mb-2 mt-3 pt-13">
                                                                <button
                                                                    className="btn btn-light-primary btn-bold me-2 "
                                                                    onClick={() => {
                                                                        Swal.fire({
                                                                            title: "Are you sure?",
                                                                            text: "Do you want to delete it !",
                                                                            icon: "warning",
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: "#d33",
                                                                            cancelButtonColor: "#0058B1",
                                                                            confirmButtonText: "Yes, I do!",
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                handleDeleteProduct(product?.id)
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    Delete
                                                                </button>
                                                                <button
                                                                    id="QrCode"
                                                                    className="btn btn-light-info btn-bold ml-2"
                                                                    onClick={() => {
                                                                        history.push(`/new-product/${product?.id}`);
                                                                    }}
                                                                >
                                                                    Edit
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {/*end::Wrapper*/}
                                </div>
                            </div>
                        </div>
                        {DataProducts?.length === 0 && isLoading === false ?
                            <div className="text-center">
                                <div>
                                    <h3

                                    > <img
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search-amico.svg")}
                                            className="rounded w-300px h-300px cursor-pointer"
                                            alt=""
                                        />
                                    </h3>
                                    <h2 className="text-muted">No result found</h2>
                                </div>

                            </div>
                            : ""}
                        {isLoading && (

                            <div className="col-md-12 mb-5 pb-5">
                                <div className="card-xl-stretch me-md-6">
                                    <div className="m-0">
                                        <div className="fs-6 fw-bold d-flex justify-content-center">
                                            <h1 className="text-muted mx-3 mt-17">
                                                Loading...
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="d-flex justify-content-end cursor-pointer">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className={`page-item ${pagination.isFirstPage ? 'disabled' : ''}`}>
                                        <span className="page-link" onClick={() => {
                                            setpageNumber(pagination.pageNumber - 1)
                                            handlePageChange(pagination.pageNumber - 1)
                                        }} aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </span>
                                    </li>

                                    {/* Display page numbers */}
                                    {Array.from({ length: pagination?.pageCount }, (_, index) => (
                                        <li key={index} className={`page-item ${pagination.pageNumber === index + 1 ? 'active' : ''}`}>
                                            <span className="page-link" onClick={() => {
                                                if (index + 1 !== pageNumber) {
                                                    setpageNumber(index + 1)
                                                    handlePageChange(index + 1)
                                                }
                                            }}>
                                                {index + 1}
                                            </span>
                                        </li>
                                    ))}

                                    <li className={`page-item ${pagination.isLastPage ? 'disabled' : ''}`}>
                                        <span className="page-link" onClick={() => {
                                            console.log(pagination.pageNumber, pagination)
                                            setpageNumber(pagination.pageNumber + 1)
                                            handlePageChange(pagination.pageNumber + 1)
                                        }} aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showCategory}
                onHide={handleCloseCategory}
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title> {(`${showEdite === true?"Update":"Create"} category`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className="  py-4 mb-3">
                            {/*begin::Card header*/}
                            <div >
                                {/*begin::Card title*/}
                                <div className="p-10">
                                    <h4>Category picture</h4>
                                </div>
                                {/*end::Card title*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className=" text-center pt-0">
                                <div
                                    className="image-input image-input-outline"
                                    id="kt_profile_avatar"
                                    style={{
                                        backgroundImage: `url('${image?.result}')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    <div
                                        className="image-input-wrapper"
                                        style={{
                                            backgroundImage: `url('${image?.result}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    />
                                    <label
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="change"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Change avatar"
                                    >
                                        <i className="fa fa-pen icon-sm text-muted"></i>
                                        <input
                                            type="file"
                                            id="profile_avatar"
                                            name="profile_avatar"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={handleImageChange}
                                        />
                                        <input type="hidden" name="profile_avatar_remove" />
                                    </label>
                                    <span
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="remove"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Remove avatar"
                                        onClick={handleImageRemove}
                                    >
                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                </div>
                                <span className="form-text text-muted">
                                    Set the category thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted
                                </span>
                            </div>
                            {/*end::Card body*/}
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Category name <span class="text-danger">*</span></label>
                            <input type="text" maxlength="50" value={CategoryName} class="form-control" id="exampleInputPassword1" placeholder="Category name"
                                onChange={(e) => {
                                    setCategoryName(e.target.value);
                                }} />
                                <div className="fs-3 d-flex justify-content-end mt-2"
                                  style={{
                                    bottom: 8,
                                    right: 8,
                                    color: characterCount === maxCharacters ? 'blue' : 'black',
                                    fontSize: '11px',
                                  }}
                                >
                                  {characterCount}/{maxCharacters}
                                </div>
                                {Messageerror === true && !!CategoryName === false?<span class="text-danger mt-3 ml-2">Category name is required</span>:""}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="d-flex justify-content-end"
                    >
                        <button
                            type="button"
                            onClick={handleCloseCategory}
                            className="btn btn-light btn-elevate"
                        >
                            {("Cancel")}
                        </button>
                        <button
                            type="button"
                            disabled={isLoadingCategory}
                            onClick={()=>{
                                
                                if(!!CategoryName === false){
                                    setMessageerror(true)
                                }else{
                                    if(showEdite === true){
                                        HandlPutCategory()
                                    }else{
                                        HandlPostCategory()
                                    }
                                    setMessageerror(false)
                                }
                            }}
                            className="btn btn-primary btn-elevate ml-3"
                        >
                            {(`${showEdite === true?"Update":"Create"}`)}{isLoadingCategory === true?<span className="spinner spinner-white ml-2 p-4">
                            </span>:""}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showDetail}
                onHide={handleCloseDetail}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title> {(`  Product Details`)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between flex-column h-100">
                        {/*begin::Container*/}
                        <div className="h-100 ">
                            {!!productDetail === true ?
                                <div className="d-flex flex-column flex-lg-row   align-items-center">
                                    {/*begin::Image*/}
                                    {productDetail !== null ?
                                        <div className="d-flex flex-column gap-7 gap-lg-10">
                                            <div
                                                className="bgi-no-repeat bgi-size-cover rounded h-250px w-350px text-center"
                                            >{productDetail?.images.length > 0 ?
                                                <Slide style={{ position: 'relative', height: '300px', width: '400px' }}>
                                                    {productDetail?.images?.map((image, index) => {
                                                        return (
                                                            <img
                                                                key={index}
                                                                src={`${image?.uriImage}`}
                                                                style={{
                                                                    maxWidth: '100%', // Set maximum width to 100% of the parent div
                                                                }}
                                                                className="rounded mb-4  h-250px cursor-pointer"
                                                                alt=""
                                                            />
                                                        )
                                                    })}

                                                </Slide>
                                                : <img
                                                    src={`/media/icons/no-image.png`}
                                                    className="rounded mb-4 w-250px h-250px cursor-pointer"
                                                    alt=""
                                                />}
                                            </div>
                                        </div>

                                        : ""}

                                    {/*end::Image*/}
                                    {/*begin::Title*/}
                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                        <div className="ml-3">
                                            <div className=" py-5 d-flex align-items-center pt-7 pb-1 m-0">
                                                <span
                                                    className=" font-weight-bolder text-dark-75 text-hover-primary font-size-h5  "
                                                >
                                                    {productDetail?.name}
                                                </span>
                                                <span
                                                    className={`label label-${productDetail?.isActive ? "success" : "danger"
                                                        } label-pill label-inline ml-5`}
                                                >
                                                    {productDetail?.isActive === true ? "Active" : "Inactive"}
                                                </span>

                                            </div>
                                            <div className="font-weight-bold text-dark-50 font-size-sm  d-flex justify-content-start">
                                                <div dangerouslySetInnerHTML={{ __html: productDetail?.description }} />
                                            </div>
                                            <h1 className="text-primary text-end  fw-bold mb-7 fs-1">${productDetail?.price}</h1>
                                            <div className="mb-10">
                                                <label className="form-label d-block mb-3">Tags</label>
                                                {productDetail?.tags?.map((tag) => {
                                                    return (
                                                        <>
                                                            {!!tag === true ? <span
                                                                className={`label label-muted label-pill label-inline mr-2`}
                                                            >
                                                                {tag}
                                                            </span> : ""}
                                                        </>
                                                    )
                                                })}
                                            </div>
                                            <div className="mb-10">
                                                <label className="form-label d-block mb-3">Categories</label>
                                                {productDetail?.categories?.map((cateory) => {
                                                    return (
                                                        <>
                                                            {!!cateory === true ? <span
                                                                className={`label label-primary label-pill label-inline mr-2 mt-1`}
                                                            >
                                                                {cateory?.name}
                                                            </span> : ""}
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                : ""}
                            {isLoadingDET && (

                                <div className="col-md-12 mb-5 pb-5">
                                    <div className="card-xl-stretch me-md-6">
                                        <div className="m-0">
                                            <div className="fs-6 fw-bold d-flex justify-content-center">
                                                <h1 className="text-muted mx-3 mt-17">
                                                    Loading...
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className="d-flex justify-content-end"
                        >
                            <button
                                type="button"
                                onClick={handleCloseDetail}
                                className="btn btn-light btn-elevate"
                            >
                                {("Cancel")}
                            </button>
                        </div>
                        {/*end::Footer*/}
                    </div>

                </Modal.Body>
            </Modal>
            <ImportGlobal
                show={ShowModal}
                handleClose={() => setShowModal(false)}
                handleShow={() => setShowModal(true)}
                handleGettContact={(prop) => { }}
                filter={{}}
                Name={true}
                TemplateLink="https://deliveryeaseblob.blob.core.windows.net/media/deliveryease/new-import-templates/Products-Template.xlsx"
                columnNameApi={"products"}
                titleModal={"Products"}
                link={"/Importing/importProducts"}
                refreshData={() =>getProducts(categorie, search, pageNumber)}
                fields={[
                    { id: 1, name: "Product name*" , required : true},
                    { id: 2, name: "description" },
                    { id: 3, name: "Categories name*" , required : true},
                    { id: 4, name: "Status" },
                    { id: 5, name: "Price*" , required : true},
                    { id: 6, name: "Tags" },
                    { id: 7, name: "Main image" },
                ]}
            />
        </div>
    );
};

export default ProductOTC;
