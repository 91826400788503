import { useEffect, useState } from 'react';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const GuidedTour = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const token = JSON.parse(localStorage.getItem("user")).token;
    const cuurentUser = localStorage.getItem('user')
    const role = JSON.parse(cuurentUser)?.roles
    const UserEmail = JSON.parse(cuurentUser)?.email
    const [IntroSeen, setIntroSeen] = useState(null)
    const [IndexStep, setIndexStep] = useState(0)
    const location = useLocation();
    const history = useHistory();
    const [ShowGetStarted, setShowGetStarted] = useState(null);

    const handleGetOrders = () => {
        console.log();
        fetch(`${BASE_URL}/RxDrop/IsRxDrop`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          Allow: "GET",
        })
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response.text().then((data) => [statusCode, data]);
            }
          })
          .then((data) => {
            if (data[0] === 200) {
              setShowGetStarted(data[1]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

    function EmailCheck(Email) {
        const currentPath = location.pathname;
        if (Email) {
            // Retrieve the current hasSeenIntro array from local storage
            const existingEmailsJSON = localStorage.getItem('hasSeenIntro');
            let hasSeenIntro = [];
            hasSeenIntro = JSON.parse(existingEmailsJSON);
            console.log(hasSeenIntro,existingEmailsJSON)
            if (existingEmailsJSON) {
                if (hasSeenIntro.includes(Email)) {
                    setIntroSeen(true)
                } else {
                    if (currentPath === "/user-profile") {
                        setIntroSeen(false)
                    } else {
                        history.push("/user-profile");
                    }
                }
            } else {
                if (currentPath === "/user-profile") {
                    setIntroSeen(false)
                } else {
                    history.push("/user-profile");
                }
            }
        }
    }
    function NavigatFS(){
        history.push("/user-profile");
    }
    function StopIntro() {
        if (UserEmail) {
            // Retrieve the current hasSeenIntro array from local storage
            const existingEmailsJSON = localStorage.getItem('hasSeenIntro');
            let hasSeenIntro = [];

            if (existingEmailsJSON) {
                // Parse the existing JSON to get the array
                hasSeenIntro = JSON.parse(existingEmailsJSON);
            }

            // Add the new email to the array
            hasSeenIntro.push(UserEmail);

            // Store the updated array back in local storage
            localStorage.setItem('hasSeenIntro', JSON.stringify(hasSeenIntro));
        }
    }
  
    useEffect(() => {
        if (role[0] === "Business Manager" || role[0] === "Business Staff") {
            if (UserEmail ) {
                handleGetOrders()
                EmailCheck(UserEmail)
            }
        }
    }, [UserEmail,ShowGetStarted])
    useEffect(() => {
        if (ShowGetStarted === true || ShowGetStarted === false) {
        const stepsBusinessManager = ShowGetStarted===true? [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                   
                },
            },
            {
                element: '#SettingsProfile',
                popover: {
                    title: 'Settings',
                    description: 'Fill in your information',
                   

                }
            },
            {
                element: '#Billing',
                popover: {
                    title: 'Billing',
                    description: 'Easily manage and organize your billing information',
                    
                }
            },
            {
                element: '#Invoices',
                popover: {
                    title: 'Invoices',
                    description: 'Here you can see all of your invoices',
                   
                }
            },
            {
                element: '#Settings',
                popover: {
                    title: 'Settings',
                    description: 'Go to Settings',
                    side: "top",
                    onNextClick: () => {
                        history.push("/settings");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                    
                }
            },
            {
                element: '#OrderCustom',
                popover: {
                    title: 'Order Custom Fields',
                    description: 'Manage your Order Custom Fields',
                    onPrevClick: () => {
                        NavigatFS();
                        setTimeout(() => {
                            
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },  
            {
                element: '#CustOrder',
                popover: {
                    title: 'Order Custom Fields',
                    description: 'Add custom fields help you extend the order to include additional fields beyond the basic fields',
                    
                }
            }, 
            {
                element: '#CustomerCustom',
                popover: {
                    title: 'Customer Custom Fields',
                    description: 'Manage your Customer Custom Fields',
                    onNextClick: () => {
                        document.getElementById("CustomerCustom").click();
                        setTimeout(() => {
                             driverObj.moveNext();
                         }, 100)
                     },
                }
            },
            {
                element: '#CustCustomer',
                popover: {
                    title: 'Customer Custom Fields',
                    description: 'Add custom fields help you extend the customer to include additional fields beyond the basic fields',
                    onPrevClick: () => {
                        document.getElementById("OrderCustom").click()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            }, 
            {
                element: '#SpecialRequirements',
                popover: {
                    title: 'Special requirements',
                    description: 'Manage your special requirements ',
                    onNextClick: () => {
                        document.getElementById("SpecialRequirements").click();
                        setTimeout(() => {
                             driverObj.moveNext();
                         }, 100)
                     },
                    
                }
            },
            {
                element: '#SpecialRequirementst',
                popover: {
                    title: 'Special Requirements',
                    description: 'Add special requirements. This allows you to quickly add tags to delivery orders (e.g: fridge item, fragile...)',
                    onPrevClick: () => {
                        document.getElementById("CustomerCustom").click()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Payments',
                popover: {
                    title: 'payments',
                    description: 'Manage your payments',
                    onNextClick: () => {
                        document.getElementById("Payments").click();
                        setTimeout(() => {
                             driverObj.moveNext();
                         }, 100)
                     },
                }
            },
            {
                element: '#GlobalPayments',
                popover: {
                    title: 'Payments',
                    description: 'Manage transactions seamlessly, such as  global payment , Moneris and Stripe',
                    side: "top",
                    onPrevClick: () => {
                        document.getElementById("SpecialRequirements").click()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Go to Dashboard',
                    onNextClick: () => {
                        history.push("/dashboard");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#LineOrder',
                popover: {
                    title: 'Dashboard',
                    description: 'Explore monthly order summaries',
                    onPrevClick: () => {
                        history.push("/settings");
                        setTimeout(() => {
                            console.log('In Dach')
                            document.getElementById("Payments").click()
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#CardStatistics',
                popover: {
                    title: 'Dashboard',
                    description: 'Statistics of Total Orders, Total Staffs, Total Customers',
                }
            },
            {
                element: '#Deliveries',
                popover: {
                    title: 'Dashboard',
                    description: 'Percentage of orders based on status (Draft, Open, Booked ...)',
                }
            },
            {
                element: '#LiveTracking',
                popover: {
                    title: 'Dashboard',
                    description: 'Enables real-time monitoring of order status and location',
                }
            },
            {
                element: '#LatestOrders',
                popover: {
                    title: 'Dashboard',
                    description: 'Displays key details of the most recent orders',
                }
            },
            {
                element: '#Managecustomers',
                popover: {
                    title: 'Manage customers',
                    description: 'Go to Manage customers',
                    onNextClick: () => {
                        history.push("/customers");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#customersTabel',
                popover: {
                    title: 'Manage customers',
                    description: 'This customer list presents the details of individual customers (name, phone, email...)',
                    side: "top",
                    onPrevClick: () => {
                        history.push("/dashboard");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Newcustomer',
                popover: {
                    title: 'Manage customers',
                    description: 'Create new customer',
                }
            },
            {
                element: '#ImportCustomer',
                popover: {
                    title: 'Manage customers',
                    description: 'Quickly add customers by uploading an Excel file',
                }
            },
            {
                element: '#RxDrop',
                popover: {
                    title: 'RxDrop',
                    description: 'Go to RxDrop',
                    onNextClick: () => {
                        history.push("/rxdrop");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },                }
            },
            {
                element: '#OrdersRxDrop',
                popover: {
                    title: 'RxDrop',
                    description: 'This order list presents the details of individual orders(order, customer, delivery...)',
                    onPrevClick: () => {
                        history.push("/customers");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#QrCode',
                popover: {
                    title: 'RxDrop',
                    description: 'Easily create a QR code for quick access to the Rxdrop website',
                }
            },
            {
                element: '#profileBtn',
                popover: {
                    title: 'RxDrop',
                    description: 'Copy link for quick access to the Rxdrop website',
                }
            },
            {
                element: '#Manageorders',
                popover: {
                    title: 'Manage orders',
                    description: 'Go to Manage orders',
                    onNextClick: () => {
                        history.push("/orders");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },   
                }
            },
            {
                element: '#OrdersTable',
                popover: {
                    title: 'Manage orders',
                    description: 'This order list presents the details of individual orders(order, customer, delivery...)',
                    side: "top",
                    onPrevClick: () => {
                        history.push("/rxdrop");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Neworder',
                popover: {
                    title: 'Manage orders',
                    description: 'Create new order',
                    side: "top",
                }
            },
            {
                element: '#exportorders',
                popover: {
                    title: 'Manage orders',
                    description: 'Export orders',
                    side: "top",
                }
            },
            {
                element: '#ImportOrders',
                popover: {
                    title: 'Manage orders',
                    description: 'Quickly add orders by uploading an Excel file',
                    side: "top",
                }
            },
            {
                element: '#Managestaff',
                popover: {
                    title: 'Manage staff',
                    description: 'Go to Manage staff',
                    onNextClick: () => {
                        history.push("/staff");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     }, 
                }
            },
            {
                element: '#StaffTable',
                popover: {
                    title: 'Manage staff',
                    description: 'This staff list presents the details of individual staffs(first name , last name, email...)',
                    onPrevClick: () => {
                        history.push("/orders");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#NewStaff',
                popover: {
                    title: 'Manage staff',
                    description: 'Create new staff',

                }
            },
            {
                element: '#HelpCenter',
                popover: {
                    title: 'Help center',
                    description: 'Go to Help center',
                    onNextClick: () => {
                        history.push("/help-center");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#VedioSearch',
                popover: {
                    title: 'Help Center',
                    description: 'Find tutorials effortlessly by typing keywords into the search',
                    onPrevClick: () => {
                        history.push("/staff");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#vedios',
                popover: {
                    title: 'Help Center',
                    description: 'Explore a collection of helpful videos for easy website navigation',
                }
            },
        ]:
        [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                   
                },
            },
            {
                element: '#SettingsProfile',
                popover: {
                    title: 'Settings',
                    description: 'Fill in your information',
                   

                }
            },
            {
                element: '#Billing',
                popover: {
                    title: 'Billing',
                    description: 'Easily manage and organize your billing information',
                    
                }
            },
            {
                element: '#Invoices',
                popover: {
                    title: 'Invoices',
                    description: 'Here you can see all of your invoices',
                   
                }
            },
            {
                element: '#Settings',
                popover: {
                    title: 'Settings',
                    description: 'Go to Settings',
                    side: "top",
                    onNextClick: () => {
                        history.push("/settings");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                    
                }
            },
            {
                element: '#OrderCustom',
                popover: {
                    title: 'Order Custom Fields',
                    description: 'Manage your Order Custom Fields',
                    onPrevClick: () => {
                        NavigatFS();
                        setTimeout(() => {
                            
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },  
            {
                element: '#CustOrder',
                popover: {
                    title: 'Order Custom Fields',
                    description: 'Add custom fields help you extend the order to include additional fields beyond the basic fields',
                    
                }
            }, 
            {
                element: '#CustomerCustom',
                popover: {
                    title: 'Customer Custom Fields',
                    description: 'Manage your Customer Custom Fields',
                    onNextClick: () => {
                        document.getElementById("CustomerCustom").click();
                        setTimeout(() => {
                             driverObj.moveNext();
                         }, 100)
                     },
                }
            },
            {
                element: '#CustCustomer',
                popover: {
                    title: 'Customer Custom Fields',
                    description: 'Add custom fields help you extend the customer to include additional fields beyond the basic fields',
                    onPrevClick: () => {
                        document.getElementById("OrderCustom").click()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            }, 
            {
                element: '#SpecialRequirements',
                popover: {
                    title: 'Special requirements',
                    description: 'Manage your special requirements ',
                    onNextClick: () => {
                        document.getElementById("SpecialRequirements").click();
                        setTimeout(() => {
                             driverObj.moveNext();
                         }, 100)
                     },
                    
                }
            },
            {
                element: '#SpecialRequirementst',
                popover: {
                    title: 'Special Requirements',
                    description: 'Add special requirements. This allows you to quickly add tags to delivery orders (e.g: fridge item, fragile...)',
                    onPrevClick: () => {
                        document.getElementById("CustomerCustom").click()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Payments',
                popover: {
                    title: 'payments',
                    description: 'Manage your payments',
                    onNextClick: () => {
                        document.getElementById("Payments").click();
                        setTimeout(() => {
                             driverObj.moveNext();
                         }, 100)
                     },
                }
            },
            {
                element: '#GlobalPayments',
                popover: {
                    title: 'Payments',
                    description: 'Manage transactions seamlessly, such as  global payment , Moneris and Stripe',
                    side: "top",
                    onPrevClick: () => {
                        document.getElementById("SpecialRequirements").click()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 100)
                    },
                }
            },
            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Go to Dashboard',
                    onNextClick: () => {
                        history.push("/dashboard");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#LineOrder',
                popover: {
                    title: 'Dashboard',
                    description: 'Explore monthly order summaries',
                    onPrevClick: () => {
                        history.push("/settings");
                        setTimeout(() => {
                            console.log('In Dach')
                            document.getElementById("Payments").click()
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#CardStatistics',
                popover: {
                    title: 'Dashboard',
                    description: 'Statistics of Total Orders, Total Staffs, Total Customers',
                }
            },
            {
                element: '#Deliveries',
                popover: {
                    title: 'Dashboard',
                    description: 'Percentage of orders based on status (Draft, Open, Booked ...)',
                }
            },
            {
                element: '#LiveTracking',
                popover: {
                    title: 'Dashboard',
                    description: 'Enables real-time monitoring of order status and location',
                }
            },
            {
                element: '#LatestOrders',
                popover: {
                    title: 'Dashboard',
                    description: 'Displays key details of the most recent orders',
                }
            },
            {
                element: '#Managecustomers',
                popover: {
                    title: 'Manage customers',
                    description: 'Go to Manage customers',
                    onNextClick: () => {
                        history.push("/customers");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#customersTabel',
                popover: {
                    title: 'Manage customers',
                    description: 'This customer list presents the details of individual customers (name, phone, email...)',
                    side: "top",
                    onPrevClick: () => {
                        history.push("/dashboard");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Newcustomer',
                popover: {
                    title: 'Manage customers',
                    description: 'Create new customer',
                }
            },
            {
                element: '#ImportCustomer',
                popover: {
                    title: 'Manage customers',
                    description: 'Quickly add customers by uploading an Excel file',
                }
            },
            {
                element: '#RxDrop',
                popover: {
                    title: 'RxDrop',
                    description: 'Go to RxDrop',
                    onNextClick: () => {
                        history.push("/rxdrop");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },                }
            },
            {
                element: '#GetStarted',
                popover: {
                    title: 'RxDrop',
                    description: 'Get Started',
                    onPrevClick: () => {
                        history.push("/customers");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Manageorders',
                popover: {
                    title: 'Manage orders',
                    description: 'Go to Manage orders',
                    onNextClick: () => {
                        history.push("/orders");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },   
                }
            },
            {
                element: '#OrdersTable',
                popover: {
                    title: 'Manage orders',
                    description: 'This order list presents the details of individual orders(order, customer, delivery...)',
                    side: "top",
                    onPrevClick: () => {
                        history.push("/rxdrop");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Neworder',
                popover: {
                    title: 'Manage orders',
                    description: 'Create new order',
                    side: "top",
                }
            },
            {
                element: '#exportorders',
                popover: {
                    title: 'Manage orders',
                    description: 'Export orders',
                    side: "top",
                }
            },
            {
                element: '#ImportOrders',
                popover: {
                    title: 'Manage orders',
                    description: 'Quickly add orders by uploading an Excel file',
                    side: "top",
                }
            },
            {
                element: '#Managestaff',
                popover: {
                    title: 'Manage staff',
                    description: 'Go to Manage staff',
                    onNextClick: () => {
                        history.push("/staff");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     }, 
                }
            },
            {
                element: '#StaffTable',
                popover: {
                    title: 'Manage staff',
                    description: 'This staff list presents the details of individual staffs(first name , last name, email...)',
                    onPrevClick: () => {
                        history.push("/orders");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#NewStaff',
                popover: {
                    title: 'Manage staff',
                    description: 'Create new staff',

                }
            },
            {
                element: '#HelpCenter',
                popover: {
                    title: 'Help center',
                    description: 'Go to Help center',
                    onNextClick: () => {
                        history.push("/help-center");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#VedioSearch',
                popover: {
                    title: 'Help Center',
                    description: 'Find tutorials effortlessly by typing keywords into the search',
                    onPrevClick: () => {
                        history.push("/staff");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#vedios',
                popover: {
                    title: 'Help Center',
                    description: 'Explore a collection of helpful videos for easy website navigation',
                }
            },
        ];
        const stepsBusinessStaff = [
            {
                element: '#Overview',
                popover: {
                    title: 'Overview',
                    description: 'Here you can check your profile information',
                   
                },
            },
            {
                element: '#SettingsProfile',
                popover: {
                    title: 'Settings',
                    description: 'Fill in your information',
                   

                }
            },
            {
                element: '#Dashboard',
                popover: {
                    title: 'Dashboard',
                    description: 'Go to Dashboard',
                    onNextClick: () => {
                        history.push("/dashboard");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#LineOrder',
                popover: {
                    title: 'Dashboard',
                    description: 'Explore monthly order summaries',
                    onPrevClick: () => {
                        NavigatFS()
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#CardStatistics',
                popover: {
                    title: 'Dashboard',
                    description: 'Card Statistics (Total Orders, Total Customers)',
                }
            },
            {
                element: '#Deliveries',
                popover: {
                    title: 'Dashboard',
                    description: 'Percentage of orders based on status (Draft, Open, Booked ...)',
                }
            },
            {
                element: '#LiveTracking',
                popover: {
                    title: 'Dashboard',
                    description: 'Enables real-time monitoring of order status and location',
                }
            },
            {
                element: '#LatestOrders',
                popover: {
                    title: 'Dashboard',
                    description: 'Displays key details of the most recent orders',
                }
            },
            {
                element: '#Managecustomers',
                popover: {
                    title: 'Manage customers',
                    description: 'Go to Manage customers',
                    onNextClick: () => {
                        history.push("/customers");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#customersTabel',
                popover: {
                    title: 'Manage customers',
                    description: 'This customer list presents the details of individual customers (name, phone, email...)',
                    side: "top",
                    onPrevClick: () => {
                        history.push("/dashboard");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Newcustomer',
                popover: {
                    title: 'Manage customers',
                    description: 'Create new customer',
                }
            },
            {
                element: '#ImportCustomer',
                popover: {
                    title: 'Manage customers',
                    description: 'Quickly add customers by uploading an Excel file',
                }
            },
            {
                element: '#Manageorders',
                popover: {
                    title: 'Manage orders',
                    description: 'Go to Manage orders',
                    onNextClick: () => {
                        history.push("/orders");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },   
                }
            },
            {
                element: '#OrdersTable',
                popover: {
                    title: 'Manage orders',
                    description: 'This order list presents the details of individual orders(order, customer, delivery...)',
                    side: "top",
                    onPrevClick: () => {
                        history.push("/customers");
                        setTimeout(() => {
                            console.log('In Dach')
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#Neworder',
                popover: {
                    title: 'Manage orders',
                    description: 'Create new order',
                    side: "top",
                }
            },
            {
                element: '#exportorders',
                popover: {
                    title: 'Manage orders',
                    description: 'Export orders',
                    side: "top",
                }
            },
            {
                element: '#ImportOrders',
                popover: {
                    title: 'Manage orders',
                    description: 'Quickly add orders by uploading an Excel file',
                    side: "top",
                }
            },
            {
                element: '#HelpCenter',
                popover: {
                    title: 'Help center',
                    description: 'Go to Help center',
                    onNextClick: () => {
                        history.push("/help-center");
                         setTimeout(() => {
                             
                             driverObj.moveNext();
                         }, 1000)
                     },
                }
            },
            {
                element: '#VedioSearch',
                popover: {
                    title: 'Help Center',
                    description: 'Find tutorials effortlessly by typing keywords into the search',
                    onPrevClick: () => {
                        history.push("/orders");
                        setTimeout(() => {
                            driverObj.movePrevious();
                        }, 1000)
                    },
                }
            },
            {
                element: '#vedios',
                popover: {
                    title: 'Help Center',
                    description: 'Explore a collection of helpful videos for easy website navigation',
                }
            },
        ];
        function StepsRe() {
            if (role[0] === "Business Manager") {
                return stepsBusinessManager
            }else if (role[0] === "Business Staff") {
                return stepsBusinessStaff
            }
        }
        function StepsDone() {
            if (role[0] === "Business Manager") {
                return "#vedios"
            }else if (role[0] === "Business Staff") {
                return "#vedios"
            }
        }
        const driverObj = driver({
            popoverClass: 'driverjs-theme',
            nextBtnText: 'Next',
            prevBtnText: 'Previous',
            doneBtnText: 'Done',
            steps: StepsRe(),
            allowClose: false,
            smoothScroll: true,
            onCloseClick: (popover, state) => {
                console.log("")
            },
            onPopoverRender: (popover, { config, state }) => {
                if (state?.activeIndex !== StepsRe()?.length - 1) {
                    const firstButton = document.createElement('button');
                    firstButton.innerText = 'Skip';
                    popover.footerButtons.appendChild(firstButton);
                    firstButton.addEventListener('click', () => {
                        StopIntro();
                        driverObj.destroy();
                    });
                }
            },
            onDestroyed: (popover, config, state) => {
                if (config?.element === StepsDone()) {
                    if (UserEmail) {
                        // Retrieve the current hasSeenIntro array from local storage
                        const existingEmailsJSON = localStorage.getItem('hasSeenIntro');
                        let hasSeenIntro = [];
                        if (existingEmailsJSON) {
                            // Parse the existing JSON to get the array
                            hasSeenIntro = JSON.parse(existingEmailsJSON);
                            // Check if the entered email is already in the array
                            if (hasSeenIntro.includes(UserEmail)) {
                                setIntroSeen(true)
                            } else {
                                StopIntro()
                            }
                        } else {
                            StopIntro()
                        }
                    }
                } else {
                    console.log("")
                }
            },
        });
        if (IntroSeen === false ) {
            setTimeout(() => {
                driverObj.drive(IndexStep);
            }, 2000)
        }
    }
    }, [IntroSeen , ShowGetStarted]);

    return null;
};

export default GuidedTour;
