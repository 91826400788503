import React from "react";
import { Link } from "react-router-dom";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export function ErrorPage404() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/404.svg")})`,backgroundPosition:'top', backgroundSize:'auto'
        }}
      >
        <Link
        to={"/dashboard"}
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "70px", position: 'absolute', left: '43%', bottom: '27%' }}
        >
         Go Back
        </Link>
      </div>
    </div>
  );
}
