// CountContext.js
import { createContext, useContext, useState } from 'react';
import React from 'react';
const CountContext = createContext();

export const CountProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [reloadRxDrop, setReloadRxDrop] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isSound, setIsSound] = useState(false);

  return (
    <CountContext.Provider
      value={{ count, setCount, setReloadRxDrop, reloadRxDrop, notifications, setNotifications, isSound, setIsSound }}
    >
      {children}
    </CountContext.Provider>
  );
};

export const useCount = () => {
  return useContext(CountContext);
};
