import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useAuthStore from "./authStore";

export default function QRCODE({ token}) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const { t } = useTranslation();
    const history = useHistory();
    const [QrCode, setQrCode] = useState(false);
    const { loginData } = useAuthStore();
    console.log(loginData?.data?.data?.token, loginData)
    const handleGetQrCode = () => {
        fetch(`${BASE_URL}/Account/TwoFactorAuthentication/Pairing`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            Allow: "GET",
        })
            .then(async (response) => {
                const statusCode = await response.status;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then((data) => [statusCode, data]);
                }
            })
            .then((data) => {
                if (data[0] === 200) {
                    console.log(data[1])
                    setQrCode(data[1]);
                } else if (data[0] === 401) {
                    history.push("/error/401");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        handleGetQrCode()
    }, [])

    return (
        <div className="px-5  ">
            <div className="h-100">
                <div className=""> 
                    <div className="text-center">
                        <h1>Register DeliveryEase</h1>
                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                            Scan this QR Code with a security or authentication app like Google Authenticator.
                        </div>
                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                            Download one from the App Store or Play Store
                        </div>
                    </div>
                    <div className="d-flex justify-content-center h-300px" dangerouslySetInnerHTML={{ __html: QrCode?.qrCode }} />
                    <div className="text-center">
                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                            Or entre the following code:
                        </div>
                        <h6>{QrCode?.manualCode}</h6>
                        <div className="flex-wrap font-weight-semibold text-dark-50 mt-3 mb-10 fs-3">
                            Once you connect your DeliveryEase account to the security
                            or authentication app, you'll get a one-time verification code whenever you need it.
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}
