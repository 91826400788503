// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { apiCalll } from "../../../../../../../_metronic/_helpers/apiHelpers";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import { UsersListLoading } from "./UsersListLoading";

const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}
export function StaffAssignPharmacyForm({
  onHide,
}) {
  const [loading, setLoading] = useState(true);
  const [AddLoading, setAddLoading] = useState(false);
  const [search, setsearch] = useState(null);
  const [pageNumberWebSite, setPageNumberWebSite] = useState(1);
  const [ValueSizeWebSite, setValueSizeWebSite] = useState(10);
  const [WebsiteCountWebSite, setWebsiteCountWebSite] = useState(10);
  const [Pages, setPages] = useState(1);
  const [ToolIds, setToolIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [Data, setData] = useState([])
  const handlePageSizeChangeWebsites = (e) => {
    const newSize = parseInt(e.target.value);
    setValueSizeWebSite(newSize);
    setPageNumberWebSite(1);
  };
  const handlePageClickWebsites = (selected) => {
    setPageNumberWebSite(selected?.selected + 1);
  };
  const toggleToolId = (id) => {
    setToolIds((prevToolIds) => {
      if (prevToolIds.includes(id)) {
        // Remove id if it exists
        return prevToolIds.filter((toolId) => toolId !== id);
      } else {
        // Add id if it doesn't exist
        return [...prevToolIds, id];
      }
    });
  };
  // const selectAllTools = () => {
  //   const allToolIds = Data?.map((tool) => tool?.id);
  //   setToolIds((prevToolIds) => {
  //     // Combine previous IDs and new IDs, ensuring no duplicates
  //     const newToolIds = Array?.from(new Set([...prevToolIds, ...allToolIds]));
  //     return newToolIds;
  //   });
  // };
  const [LoaderSelect, setLoaderSelect] = useState(false)

   const selectAllTools = () => {
    setLoaderSelect(true)
    apiCalll({
      method: "GET",
      link: `/dispatcher/UnassignedPharmacyIds?DispatcherId=${id}`,
    })
      .then(async (result) => {
        if (result[0] === 200) {
          setToolIds(result[1]);
          setLoaderSelect(false)
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoaderSelect(false)
      });
   };
  
  const isToolIdSelected = (id) => {
    return ToolIds.includes(id);
  };

  const { id } = useParams();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const history = useHistory();

  const AddTool = async () => {
    setAddLoading(true)
    // const universityId = id;
    await fetch(`${BASE_URL}/Dispatcher/${id}/AssignPharmacies`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `bearer ${token}`,
      },
      Allow: "POST",
      body:JSON.stringify({ids:ToolIds})
    })
      .then(async (response) => {
        const statusCode = response.status;
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        setAddLoading(false)

        if (data[0] === 200) {
          if(ToolIds?.length > 1){
            Swal.fire({
              icon: "success",
              title: ("Success"),
              html: `The pharmacies were successfully linked`,
            });
          }else{
            Swal.fire({
              icon: "success",
              title: ("Success"),
              html: `The pharmacy was successfully linked`,
            });
          }
        
          onHide()
          document.getElementById("refreshPharmaciesAssigned").click();
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        } else if (data[0] === 401) {
          history.push("/error/401");
        } else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: ("You have to subscribe first!"),
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
        setAddLoading(false)
        return [400];
      });

  };
  function GetTools(search, pageNm, pageSz) {
    setLoading(true)

    apiCalll({
      method: "GET",
      link: `/Dispatcher/UnassignedPharmacies?dispatcherId=${id}&search=${!!search === true ? search : ""}&pageNumber=${pageNm}&pageSize=${pageSz}`,
    })
      .then(async (result) => {
        if (result[0] === 200) {
          setData(result[1]?.data)
          setWebsiteCountWebSite(result[1]?.pagination?.totalItemCount);
          setPages(result[1]?.pagination?.pageCount);
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false)
      });
  }
  //   useEffect(() => {
  //     GetCarousel(null, pageNumberWebSite, ValueSizeWebSite)
  // }, [pageNumberWebSite, ValueSizeWebSite])
  useEffect(() => {
    setTimeout(() => {
      GetTools(search, pageNumberWebSite, ValueSizeWebSite)
    }, 500);
  }, [search, pageNumberWebSite, ValueSizeWebSite])
  useEffect(() => {
    const allIdsSelected = Data?.length > 0 && Data?.every((tool) => ToolIds?.includes(tool?.id));
    setAllSelected(allIdsSelected);
  }, [Data, ToolIds]);
  return (
    <>
      {/* begin::Scroll */}
      <div
        className='d-flex flex-column'
        id='kt_modal_add_user_scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_modal_add_user_header'
        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
        data-kt-scroll-offset='300px'
      >
        <div className='row mt-5'>
          <div className="col-lg-5 col-md-7 col-12 mb-3">
            <div className="d-flex align-iteml-center px-5">
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-lg rounded-pill  "
                placeholder="Search..."
                value={search}
                onChange={(e) => {
                  setsearch(e.target.value)
                  setTimeout(() => {
                    setPageNumberWebSite(1)
                  }, 600);
                }}
              />
            </div>
          </div>
        </div>
        <div className="checkbox-inline ml-5 mt-3 mb-5">
          <label className="checkbox checkbox-lg font-weight-bold font-size-lg">
            <input type="checkbox" defaultChecked="checked" name="Checkboxes3_1"
              onChange={(e) => {
                if (e.target.checked === true) {
                  selectAllTools()
                } else {
                  setToolIds([]);
                }
              }}
              checked={allSelected}
            />
            <span />
            Select All
          </label>
        </div>
        {LoaderSelect && <UsersListLoading />}

        <div className=''>
          {Data?.map((tool) => {
            return <div className="mb-3 col-12 mr-3">
              <div className=" d-flex justify-content-between align-items-center flex-wrap rounded border border-secondary rounded-3 h-lg-100 p-3">
                <div className="mb-3  fw-semibold">
                  <div className='d-flex  align-items-center'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-circle symbol-50px overflow-hidden mr-3'>
                        <a href='#'>
                          {tool?.profilePicture ? (
                            <div className='symbol-label'>
                              <img src={tool?.profilePicture} alt={tool?.pharmacy}  style={{ objectFit: "cover" }} className='w-100' />
                            </div>
                          ) : (
                            <div className='symbol-label'>
                              <img src="/media/users/blank.png" alt={tool?.pharmacy} className='w-100' />
                            </div>
                          )}
                        </a>
                      </div>
                      <div>
                        <h5 className="text-gray-900 fw-bold text-break">{tool?.pharmacy} </h5>
                        <div className="fs-6 text-gray-700 ">
                        {!!tool?.city ? tool?.city :""} {!!tool?.city === true && !!tool?.state === true ? ",":""} {!!tool?.state ?tool?.state:""} {!!tool?.state === true && !!tool?.country === true ? ",":""} {!!tool?.country ?tool?.country:""}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkbox-inline ml-5">
                  <label className="checkbox checkbox-lg fs-1">
                    <input type="checkbox" defaultChecked="checked" name="Checkboxes3_1"
                      checked={isToolIdSelected(tool?.id)}
                      onChange={() => toggleToolId(tool?.id)}
                    />
                    <span />
                  </label>
                </div>
              </div>
            </div>
          })}
        </div>
        {(loading && Data?.length === 0) &&
          <div className='w-100 mt-6 mb-6 d-flex justify-content-center'><div style={{ ...styles, textAlign: 'center' }}>Processing...</div></div>}
        {Data?.length > 0 ? (
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-10">
            <div className="col-sm-10 col-md-5 d-flex align-items-center  mx-3 mt-1">
              <span className="mr-5 pr-3 fw-bold">
                Total rows: {WebsiteCountWebSite}
              </span>
              <div className="mr-5 d-flex align-itemls-center">
                <label className="mr-3 fw-bold mt-2" htmlFor="rowsPerPageSelect">
                  Rows per page:
                </label>
                <select onChange={handlePageSizeChangeWebsites} value={ValueSizeWebSite}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <div className="mt-5">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={Pages}
                forcePage={pageNumberWebSite - 1}
                onPageChange={handlePageClickWebsites}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>

          </div>
        ) : (
          ""
        )}
        {(Data?.length === 0 && loading === false) && (
          <div className=" w-100 d-flex justify-content-center mt-10 mb-10">
            <div className="text-center">
              <h2 className="text-muted">No pharmacies available</h2>
            </div>
          </div>
        )}
      </div>
      <div className='text-center mt-5 mb-5'>
        <button
          type='reset'
          onClick={() => onHide()}
          className='btn btn-light mr-3 rounded-pill'
          data-kt-users-modal-action='cancel'
        >
          Discard
        </button>

        <button
          type='button'
          className='btn btn-primary rounded-pill'
          disabled={AddLoading}
          onClick={() => {
            if (ToolIds?.length > 0) {
              AddTool()
            } else {
              Swal.fire({
                title: "Info!",
                text: "Please select a pharmacy before proceeding.!",
                icon: "info",
                confirmButtonText: "Ok",
              })
            }
          }}
        >
          {(AddLoading === true) ? (
            <span className='indicator'>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ml-2'></span>
            </span>
          ) : <span className='indicator-label'>Submit</span>}
        </button>
      </div>
      {/* end::Actions */}


    </>
  );
}
