import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConvertToDateCurrentTimeZoneDateTime, ConvertToDateCurrentTimeZoneDateTime24Hour } from "../../../../../../../_metronic/_helpers/GlobalHelpers";

export const StatusColumnFormatter = (cellContent, row) => {
  console.log("🚀 ~ StatusColumnFormatter ~ row:", row)
  let colotCode;
  switch (row.status) {
    case "Draft":
      colotCode = "#C0C0C0";
      break;
    case "Open":
      colotCode = "#4caf50";
      break;
    case "Booked":
      colotCode = "#8950FC";
      break;
    case "Out for pickup":
      colotCode = "#32C5D2";
      break;
    case "Ready for pickup":
      colotCode = "#ff7c43";
      break;
    case "In transit":
      colotCode = "#6993ff";
      break;
    case "Assigned":
      colotCode = "#FFA800";
      break;
    case "Delivered":
      colotCode = "#009688";
      break;
    case "Closed":
      colotCode = "#991f17";
      break;
    case "RxDrop":
      colotCode = "#F3A694";
      break;
    case "RTS":
      colotCode = "#6D73D9";
      break;
    case "Pending Driver":
      colotCode = "#F64E7B";
      break;

    default:
      colotCode = "";
      break;
  }

  return (
    <>
      <span
        className={`label label-lg text-white label-inline font-weight-bold py-4`}
        style={{ backgroundColor: colotCode, whiteSpace: "nowrap" }}
      >
        {row.status}
      </span>

      {row.status === "RTS" && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="metronic-features">
              {row.rtsStatus === true ? (
                <span>
                  Returned at{" "}
                  {row?.is12HourFormat
                    ? ConvertToDateCurrentTimeZoneDateTime(
                        row?.actualDeliveryRtsDate
                      )
                    : ConvertToDateCurrentTimeZoneDateTime24Hour(
                        row?.actualDeliveryRtsDate
                      )}
                </span>
              ) : (
                "Not Returned"
              )}
            </Tooltip>
          }
        >
          <span
            style={{ cursor: "pointer" }}
            className={`svg-icon svg-icon-md  ml-2 ${row.rtsStatus === true &&
              "svg-icon-success"}`}
          >
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")} />
          </span>
        </OverlayTrigger>
      )}
    </>
  );
};
