import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../_redux/authRedux";
import { login } from "../../_redux/authCrud";
import { connect } from "react-redux";
import useAuthStore from "./authStore";
import { apiCalll } from "../../../../../_metronic/_helpers/apiHelpers";

function TwoFactor(props) {
    console.log(props)
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    var url = new URL(window.location.href);
    var code = url.searchParams.get("code");
    const [accountverified, setaccountverified] = useState(true);
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showEX, setshowEX] = useState(false);

    const { loginData } = useAuthStore();
    console.log(loginData)
    useEffect(() => {
        console.log(loginData)
        if (loginData === null) {
            props.history.push("/auth/login");
        }
    }, [loginData])
    return (
        <div>
            <div className="row px-30"
                style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
            >
                <div className="col-xl-7 col-lg-7 col-md-10 col-12 px-40 pt-20">
                    <div className="card card-custom shadow ">
                        <div className="card-body h-200">
                            <div className="login-signin">
                                <h1 className="font-weight-bolder mt-5">
                                    Set up Two-Factor Authentication
                                </h1>
                                <div className="flex-wrap font-weight-semibold  text-dark-50">
                                    Two-factor authentication adds an extra layer of security. Once activated,
                                    you'll need to provide two forms of identification when accessing DeliveryEase .
                                </div>
                                <div className=" flex-wrap font-weight-semibold  text-dark-50 mt-3 mb-10">
                                    Supported methods include security apps like Google Authenticator and SMS text messages.
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.history.push("/auth/SetUp");
                                        }}
                                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mr-3"
                                    >
                                        <span>Set up 2FA</span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            setShow(true);
                                        }}
                                        className="btn btn-outline-primary font-weight-bold px-9 py-4 my-3 "
                                    >
                                        <span>Skip for now</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-10 col-12  p-7 ">
                    <div className="d-flex flex-row-fluid flex-column justify-content-center">
                        <div className="flex-column-auto -lg-0 pb-10 ">
                            <div className="d-flex justify-content-center align-items-center">
                                <img
                                    alt="Logo"
                                    className="h-400px"
                                    src="/media/icons/Two factor authentication-bro.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {t("Accounts without MFA may be less secure")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className=" flex-wrap font-weight-semibold  text-dark-50 px-5">
                            Multi-factor authentication helps your account (and the customer data it contains)
                            stay secure. Are you sure you want to skip MFA set-up?
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            disabled={loading}
                            id="SKIP2FA"
                            onClick={async () => {
                                setLoading(true);
                                //api call
                                await apiCalll({
                                    method: "POST",
                                    link: `/Account/TwoFactorAuthentication/Reminded`,
                                })
                                    .then((res) => {
                                        console.log(loginData?.data?.accessToken)
                                        props.login(loginData?.data?.accessToken)
                                        setLoading(true);
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                        console.log(loginData?.data?.accessToken)
                                        props.login(loginData?.data?.accessToken)
                                        setLoading(true);
                                    });


                            }}
                            className="btn btn-primary btn-elevate mr-5"
                        >
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                className="mr-3"
                                role="status"
                                aria-hidden="true"
                            />}
                            {t("Skip Set up")}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setShow(false)
                            }}
                            className="btn btn-light btn-elevate"
                        >
                            {t("Cancel")}
                        </button>
                        <> </>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>


    );
}
export default injectIntl(connect(null, auth.actions)(TwoFactor));

