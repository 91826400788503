/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Terms from "./Terms";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import Activationemail from "./Activationemail";
import Activationemaildriver from "./ActivationEmailDriver";
import ResetPassword from "./ResetPassword";
import { useTranslation } from "react-i18next";
import PaymentDelivery from "../../Payment";
import TwoFactor from "./Two-factor/TwoFactor";
import { useHistory } from 'react-router-dom';
import SetUp from "./Two-factor/Steps";
import useAuthStore from "./Two-factor/authStore";
import VerifacationCode from "./VerifacationCode";
import LiveTracking from "../../ECommerce/pages/liveTracking/LiveTracking";

export function AuthPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const [CheckLick, setCheckLick] = useState(null);
  const [data, setdata] = useState(null);
  const { loginData } = useAuthStore();
  useEffect(() => {
    setdata(loginData)
  }, [])
  useEffect(() => {
    const currentPath = history.location.pathname;
    setCheckLick(currentPath);
    const unlisten = history.listen((location) => {
      setCheckLick(location.pathname);
    });
    return () => unlisten();
  }, [history]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className={`d-flex flex-column flex-row-fluid position-relative ${CheckLick?.split("/")[1] === "t" ? "p-0" : "p-7"}  overflow-auto`}>
           { CheckLick?.split("/")[1] === "t" ? "" :
           <div className="">
              <LanguageSelectorDropdown />
            </div>}
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className={`${CheckLick === "/auth/SetUp" ? "" : "d-flex flex-column-fluid flex-center"}`}>
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute path="/auth/login">
                  <Login history={history} />
                </ContentRoute>
                <ContentRoute path="/auth/verification" component={VerifacationCode} />

                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute path="/auth/twoFactor">
                  <TwoFactor history={history} />
                </ContentRoute>
                <ContentRoute path="/auth/SetUp">
                  <SetUp history={history} currentPath={CheckLick} data={data} />
                </ContentRoute>
                <ContentRoute path="/ResetPassword" component={ResetPassword} />
                <ContentRoute path="/auth/terms" component={Terms} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/activateaccount"
                  component={Activationemail}
                />
                <ContentRoute
                  path="/activatedriveraccount"
                  component={Activationemaildriver}
                />
                {/* <ContentRoute
                  path="/rxdrop/:id"
                  component={RxDropPage}
                /> */}
                <ContentRoute
                  path="/deliveryPayment"
                  component={PaymentDelivery}
                />
                <ContentRoute
                  path="/t/:id"
                  component={LiveTracking}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            {CheckLick === "/auth/twoFactor" || CheckLick?.split("/")[1] === "t" || CheckLick === "/auth/SetUp"? "" :
              <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; {new Date().getFullYear()} DeliveryEase
                </div>
                <div className="d-flex order-1 order-sm-2 my-2">
                  <a
                    href="https://deliveryease.co/privacy-policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark-50"
                  >
                    {t("Privacy Policy")}
                  </a>
                  <a
                    href="https://deliveryease.co/contact-us.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark-50 ml-10"
                  >
                    {t("Contact")}
                  </a>
                </div>
              </div>
            }
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
          {/*begin::Aside*/}
          {CheckLick === "/auth/twoFactor" || CheckLick?.split("/")[1] === "t" ?
            <></>: CheckLick === "/auth/SetUp" ? <></> :
             
                <div
                  className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
                  }}
                >
                  {/*begin: Aside Container*/}
                  <div className="d-flex flex-row-fluid flex-column justify-content-between">
                    {/* start:: Aside header */}
                    <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                      <img
                        alt="Logo"
                        className="max-h-40px"
                        src={toAbsoluteUrl("/media/logos/logo-letter-1.svg")}
                      />
                    </Link>
                    {/* end:: Aside header */}

                    {/* start:: Aside content */}
                    {/* <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 className="font-size-h1 mb-5 text-white">
                Welcome to Metronic!
              </h3>
              <p className="font-weight-lighter text-white opacity-80">
                The ultimate Bootstrap & React 16 admin theme framework for
                next generation web apps.
              </p>
            </div> */}
                    {/* end:: Aside content */}

                    {/* start:: Aside footer for desktop */}
                    <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                      <div className="opacity-70 font-weight-bold	text-white">
                        &copy; {new Date().getFullYear()} DeliveryEase
                      </div>
                      <div className="d-flex">
                        <a
                          href="https://deliveryease.co/privacy-policy.html"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white"
                        >
                          {t("Privacy Policy")}
                        </a>
                        <a
                          href="https://deliveryease.co/contact-us.html"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white ml-10"
                        >
                          {t("Contact")}
                        </a>
                      </div>
                    </div>
                    {/* end:: Aside footer for desktop */}
                  </div>
                  {/*end: Aside Container*/}
                </div>

          }

          {/*begin::Aside*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
