import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardElementOptions } from '@stripe/stripe-js'
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'

const CheckoutForm = ({
  handleSubmit2,
  setbilling,
  handleClose,
  Billing,
  sub,
  formik,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      // Handle the case where the card element is missing
      return;
    }

    const result = await stripe.createToken(cardElement, {
      name: !!Billing && !!Billing?.cardName ? Billing.cardName : formik.values.cardName,
    });
    if (result.error) {
      console.error(result.error.message);
      Swal.fire({
        text: result.error.message,
        icon: "info",
        showConfirmButton: true,
        timer: 3000,
        width: "25rem",
      });
    } else {
      console.log(result, result.token?.id, result.token?.card?.id);

      setbilling({
        ...Billing,
        cardToken: result.token.id,
        type: result.token.type,
        cardId: result.token?.card?.id,
      });
      handleSubmit2({
        ...Billing,
        cardToken: result.token.id,
        type: result.token.type,
        cardId: result.token?.card?.id,
      });

      if (!!props?.nextStep) {
        props.nextStep();
      }
    }
  };

  const handleChange = (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    console.log(event?.complete);
  };
  // const cardElementOptions: StripeCardElementOptions = {
  //   hidePostalCode: true,
  // }
  return (
    <>
      <div
        className="custom-card-element-container"
        style={{
          display: "block",
          width: "100%",
          fontSize: "1.1rem",
          padding: "0.975rem 1rem",
          fontWeight: 500,
          lineHeight: 1.5,
          color: "#5e6278",
          backgroundColor: "#f9f9f9",
          backgroundClip: "padding-box",
          border: "#e1e3ea",
          borderRadius: "0.475rem",
          boxShadow: "none",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
          outline: "none",
        }}
      >
        <CardElement
          options={{ hidePostalCode: true }}
          onChange={handleChange}
          className="custom-card-element"
        />
      </div>
      <div className="d-flex justify-content-end mb-6">
        <button
          id={"Strip_billing_pay"}
          type="button"
          hidden={true}
          className="btn btn-primary ml-2"
          disabled={sub}
          onClick={handleSubmit}
        >
          <span className="indicator-label">Submit</span>
          {sub && (
            <Spinner
              className="ms-2"
              animation="border"
              variant="light"
              size="sm"
              hidden={!sub}
            />
          )}
        </button>
        <button
          hidden={true}
          type="reset"
          onClick={handleClose}
          className="btn btn-light"
        >
          Discard
        </button>
      </div>
    </>
  );
};

export default CheckoutForm;
