import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import userSlice from "../app/modules/ECommerce/_redux/customers/userSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  users : userSlice
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
