import React from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { InputNumber } from "antd";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import moment from "moment";

const InvoiceShort = (props) => {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  return (
    <>
      <div className="card" ref={props.componentRef}>
        <div className="card-body py-20">
          <div className="mw-lg-950px mx-auto w-100">
            <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
              <div>
                <h1 className="pb-7">
                  INVOICE{" "}
                  {props?.invoices?.invoiceNumber &&
                    "#" + props?.invoices?.invoiceNumber}
                </h1>
                {props?.startDate && props?.endDate && (
                  <div className="d-flex flex-stack mb-3">
                    <h5 className="fw-semibold pr-2">Pay Period :</h5>
                    <p className="text-end font-size-lg font-weight-semibold mx-3">
                      {moment(props?.startDate).format("MMMM DD, YYYY")}
                      {" - "}
                      {moment(props?.endDate).format("MMMM DD, YYYY")}
                    </p>
                  </div>
                )}
                <div className="d-flex flex-stack mb-3">
                  <h5 className="fw-semibold pr-2">Date of issue</h5>
                  <p className="text-end font-size-lg font-weight-semibold">
                    {
                       props?.invoices?.created ? moment(props?.invoices?.created).format("MMMM DD, YYYY") :  moment().format("MMMM DD, YYYY")
                    }
                  </p>
                </div>
                <div className="d-flex flex-stack mb-3">
                  <h4 className="fw-semibold pr-2">Date due </h4>
                  <p className="text-end font-size-lg font-weight-semibold">
                    {
                       props?.invoices?.created ? moment(props?.invoices?.created).format("MMMM DD, YYYY") :  moment().format("MMMM DD, YYYY")
                    }
                  </p>
                </div>
              </div>

              <div className="text-sm-end">
                <a href="#">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-letter-2.svg")}
                  />
                </a>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
              <div>
                <h5 className="font-weight-bold pe-5 pb-2">
                  {props?.invoices?.businessName || "DeliveryEase Inc."}
                </h5>
                {!props?.invoices?.address && (
                  <>
                    <div className="text-end font-size-md font-weight-bolder">
                      2100 Bloor Street West
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      Suite 6164
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      Toronto, ON, M6S 5A5
                    </div>
                  </>
                )}
                {props?.invoices?.address && (
                  <>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.address?.line1}
                    </div>
                    {props?.invoices?.address?.line2 && (
                      <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.address?.line2}
                      </div>
                    )}
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.address?.city}
                      {", "}
                      {props?.invoices?.address?.state}
                      {", "}
                      {props?.invoices?.address?.postalCode}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.address?.country === "CA" ? "Canada" : props?.invoices?.address?.country}
                    </div>
                  </>
                )}
                  <div className="text-end font-size-md font-weight-bolder">
                     accounting@deliveryease.co
                  </div>
                  <div className="text-end font-size-md font-weight-bolder">
                   +1 (855) 272-7625
                  </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      GST/HST Registration No: {props?.invoices?.hstNumber}
                    </div>
              </div>
              {
                <div className="">
                  <div className="d-flex flex-column">
                    <h5 className="font-weight-bold mb-3">Bill To</h5>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.pharmacyDetails?.name}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.pharmacyDetails?.address1}
                    </div>
                    {props?.invoices?.pharmacyDetails?.address2 && (
                      <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.pharmacyDetails?.address2}
                      </div>
                    )}
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.pharmacyDetails?.city}
                      {", "}
                      {props?.invoices?.pharmacyDetails?.state}
                      {", "}
                      {props?.invoices?.pharmacyDetails?.zipCode}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.pharmacyDetails?.country}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.pharmacyDetails?.phoneNumber}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.pharmacyDetails?.email}
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="">
              <div className="d-flex flex-columnw-100">
                <h1 className="font-weight-bold mb-3">
                  {formatCurrency(props?.invoices?.totalWithTax)} due{" "}
                  {
                      props?.invoices?.created ? moment(props?.invoices?.created).format("MMMM DD, YYYY") :  moment().format("MMMM DD, YYYY")
                  }
                  {/* <span className="text-danger">(Not Including Taxes)</span> */}
                </h1>
              </div>
              <div className="">
                <div className="flex-grow-1 pt-8 mb-13">
                  <div className="table-responsive mb-14">
                    <table className="table align-middle table-nowrap table-centered mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: 400 }}>Description</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Quantity</th>
                          <th className="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14 mb-1">
                                {props?.invoices?.startDate &&
                                props?.invoices?.endDate ? (
                                  <div className="d-flex flex-stack mb-3">
                                    <p className="text-end font-size-lg font-weight-semibold mx-3">
                                      Orders From:{" "}
                                      {moment(
                                        props?.invoices?.startDate
                                      ).format("MMMM DD, YYYY")}
                                      {" To: "}
                                      {moment(props?.invoices?.endDate).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </p>
                                  </div>
                                ) : (
                                  "Orders"
                                )}
                              </h5>
                              {/* <p className="mb-0">
                                      {new Date().toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      })}
                                    </p> */}
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{props?.invoices?.groupedOrders?.count}</td>
                          <td className="text-end">
                            {!props.isLoading ? (
                              formatCurrency(
                                props?.invoices?.groupedOrders?.charge
                              )
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            )}
                          </td>
                        </tr>
                       {props?.extra || props?.invoices?.extra ?  <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            {(!props.showExtra &&
                              props?.adjustmentDescription) ||
                              props?.invoices?.adjustmentDescription ||
                              "Adjustment"}
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder d-flex align-items-center">
                            {props.showExtra && (
                              <>
                                <InputNumber
                                  style={{
                                    width: "270px",
                                  }}
                                  type="number"
                                  addonBefore="$"
                                  addonAfter=""
                                  value={props?.extra}
                                  controls={false}
                                  onChange={(e) => {
                                    props.setExtra(e);
                                    props.setDisablePay(true);
                                  }}
                                />
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="metronic-features">
                                      {props.disablePay
                                        ? "Re-calculate charges"
                                        : "No charges to re-calculate"}
                                    </Tooltip>
                                  }
                                >
                                  <button
                                    onClick={() => {
                                      props.ids.length > 0
                                        ? props.GetTotalInvoice()
                                        : props.handleGetTotalInvoiceByDate();
                                    }}
                                    disabled={!props.disablePay}
                                    className={`btn btn-sm btn-icon btn-light-success mx-2 ${props.disablePay &&
                                      "active"}`}
                                  >
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Update.svg"
                                      )}
                                    />
                                  </button>
                                </OverlayTrigger>
                              </>
                            )}
                            {!props.showExtra &&
                              formatCurrency(
                                props?.extra || props?.invoices?.extra || 0
                              )}
                          </td>
                        </tr> : ""
                        }
                        <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            Total With Adjustment
                          </th>
                          <td className="border-0 text-end font-size-md font-weight-bolder">
                            {!props.isLoading ? (
                              formatCurrency(props?.invoices?.totalWithExtra)
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0"></td>
                          <td className="border-0"> </td>
                          <td className="border-0"></td>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            HST - Canada (
                            {Math.round(props?.invoices?.taxPercentage * 100) /
                              100}
                            % on{" "}
                            {!props.isLoading ? (
                              formatCurrency(
                                props?.invoices?.totalWithExtra
                                  ? props?.invoices?.totalWithExtra
                                  : props?.invoices?.totalWithSirviceFee
                              )
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            )}
                            )
                          </th>
                          <td className="border-0 text-end font-size-md font-weight-bolder">
                            {!props.isLoading ? (
                              formatCurrency(props?.invoices?.hstTax)
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            Total
                          </th>
                          <td className="border-0 text-end font-size-md font-weight-bolder">
                            {!props.isLoading ? (
                              formatCurrency(props?.invoices?.total)
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            )}
                          </td>
                        </tr>
                        {props?.invoices?.totalCod && props?.invoices?.totalCod !== 0 ? <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            COD
                          </th>
                          <td
                            className={`border-0 text-end border-0 font-size-md font-weight-bolder ${props
                              ?.invoices?.totalCod > 0 && "text-danger"}`}
                          >
                            {props?.invoices?.totalCod > 0 ? "-" : ""}
                            {!props.isLoading ? (
                              formatCurrency(props?.invoices?.totalCod)
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                              />
                            )}
                          </td>
                        </tr> : ""}
                        {/* <tr>
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <th
                              scope="row"
                              colSpan={2}
                              className="border-0 text-end"
                            >
                              Processing fee
                            </th>
                            <td className="border-0 text-end border-0 font-size-md font-weight-bolder">
                              {!props.isLoading ? formatCurrency(props?.invoices?.totalCodFee || 0) : <Spinner animation="border" variant="primary" size="sm"/>}

                            </td>
                          </tr> */}
                        {props?.invoices?.creditAmount && props?.invoices?.creditAmount !== 0 && <tr>
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <th
                              scope="row"
                              colSpan={2}
                              className="border-0 text-end"
                            >
                              Total Credit Amount
                            </th>
                            <td className="border-0 text-end">
                              <h4 className="m-0 font-size-md font-weight-bolder">
                                {formatCurrency(props?.invoices?.creditAmount)}
                              </h4>
                            </td>
                          </tr>}
                        <tr>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <td className="border-0"></td>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            Amount due
                          </th>
                          <td className="border-0 text-end">
                            <h4 className="m-0 font-size-md font-weight-bolder">
                              {!props.isLoading ? (
                                formatCurrency(props?.invoices?.totalWithTax)
                              ) : (
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="sm"
                                />
                              )}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="font-weight-bold pe-5 pb-2">
                  DeliveryEase Inc.
                </h5>
                <p className="text-end font-size-md font-weight-bolder">
                  Humbled to be your pharmacy delivery partner 🙂
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {props.isLoading && (
        <>
          <div className="d-flex justify-content-center">
            <h1>
              Generating invoice {props?.name && "for" + " "}
              <span className="text-primary">{props?.name}</span> ...
            </h1>
          </div>
          <div className="d-flex justify-content-center">
            <div className="spinner spinner-primary my-10"></div>
          </div>
        </>
      )} */}
    </>
  );
};

export default InvoiceShort;
