import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StaffAssignPharmacyDialogHeader } from "./StaffAssignPharmacyDialogHeader";
import { StaffAssignPharmacyForm } from "./SatffAssignPharmacyForm";

export function StaffAssignPharmacyDialog({ id, show, onHide }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();

  const handleUpdatePassword = (values) => {
    // add id to values
    values.id = id;
    setIsLoading(true)
    fetch(`${BASE_URL}/Staff/Password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      Allow: "PUT",
      body: JSON.stringify(values),
    })
    .then(async (response) => {
      const statusCode = await response.status;
      const contentType = response.headers.get("content-type");
      if (
        contentType &&  contentType.indexOf("application/json") !== -1
      ) {
        return response.json().then((data) => [statusCode,data]);
      } else {
        return response.text().then((data) => [statusCode,data]);
      }
    })
    .then(data => {
      if(data[0] === 200){
        setIsLoading(false)
         Swal.fire({
          title: "Success!",
          text: t("Password has been updated successfully!"),
          icon: "success",
          confirmButtonText: "Ok",
        });
        onHide()
      }
      else if(data[0] === 400 && data[1].message){
        setIsLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data[1].message,
          })
      }
      else if(data[0] === 400){
        setIsLoading(false)
        let tt = JSON.parse(data[1]);
        for (const [key, value] of Object.entries(tt.errors)) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: value,
          })
        }
      }
      else if(data[0] === 402){
        setIsLoading(false)
        Swal.fire({
          title: "Info!",
          text: t("You have to subscribe first"),
          icon: "info",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/user-profile");
          }
        });
      }
      else if(data[0] === 500){
        setIsLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: t('Something went wrong!. Please try again later.'),
        })
      }
    })
    .catch((error) => {
      setIsLoading(false)
      console.log(error);
    });
  }

  // server request for saving customer
  const updatePassword = (customer) => {
    handleUpdatePassword(customer);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <StaffAssignPharmacyDialogHeader />
      <StaffAssignPharmacyForm
        savePassword={updatePassword}
        actionsLoading={isLoading}
        onHide={onHide}
      />
    </Modal>
  );
}
