import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useState } from 'react';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.

const Payment = ({
  publishedKey,
  clientSecret,
  handleSubmit,
  setbilling,
  handleClose,
  Billing,
  sub,
  formik,
  key,
  nextStep,
}) => {
  const[stripePromise,setStripePromiseKey] = useState("");
  useEffect(() => {
    if(!!publishedKey === true) setStripePromiseKey(loadStripe(publishedKey));
  },[publishedKey]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return !!stripePromise === false ? (
    <h3>Loading...</h3>
  ) : (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          handleSubmit2={handleSubmit}
          nextStep={nextStep}
          setbilling={setbilling}
          handleClose={handleClose}
          Billing={Billing}
          sub={sub}
          formik={formik}
        />
      </Elements>
    </>
  );
};

export default Payment;
