import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const token = JSON.parse(localStorage.getItem("user") ?? "").token;

export async function apiCalll(props) {
  const token = !!props?.token ? props?.token : JSON.parse(localStorage.getItem("user") ?? "")?.token;
  return await fetch(`${BASE_URL}${props?.link}`, {
    method: props?.method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + (!!props?.token ? props.token : token),
    },
    body: JSON.stringify(props?.data),
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => [statusCode, data]);
      } else {
        return response.text().then((data) => [statusCode, data]);
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function ItemNumberStep(props) {
  return (
    <Fragment>
      <div className="col-2">
        <div
          className={`${
            props?.currentIndex == props?.number ? "activeCardNumber" : ""
          } itemCardNumber mt-1 bgCardNumber`}
        >
          {props?.number}
        </div>
      </div>
      <div className="pt-1 px-md-8 col-10">
        <Row>
          <Col>
            <span className="titleItem">{props?.title}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="subTitleItem text-muted">{props?.subTitle}</span>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export function creditCardTypeFromNumber(number) {
  try {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null) return "Visa";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return "MasterCard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "Amex";

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null) return "Diners";

    // Diners
    if (/^3(0[0-5]|[68])\d{11}$/.test(number)) {
      alert()
      return "Diners";
    };
    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) return "Visa Electron";

    // Union Pay
    if (number.match(/^(62[0-9]{14,17})$/)) return "Union Pay";
    return "";
  } catch (err) {
    console.log("~err", err);
  }
}

export function setValueField(data, setData, value, column) {
  try {
    let dataSet = Array.isArray(data) ? [...data] : { ...data };
    dataSet[column] = value;
    setData(dataSet);
  } catch (err) {}
}

export function ModelStateMessage(result) {
  try {
    result[1] = typeof result[1] == "string" ? JSON.parse(result[1]) : result[1];
    if (
      result[1].modelState !== null &&
      result[1].modelState !== undefined &&
      result[1].modelState !== ""
    ) {
      let modelState = result[1].modelState;
      if (modelState)
        Object.keys(modelState).forEach(function(k) {
          modelState[k].forEach((element) => {
            Swal.fire("Oops?", element, "error");
          });
        });
    } else if (!!result[1]?.errors) {
      var p = result[1]?.errors;
      var list = [];
      var list2 = [];
      for (var key in p) {
        if (p.hasOwnProperty(key)) {
          list2.push({ key: key, value: p[key] });

          var a = list.findIndex(
            (x) => x === `<div class="col-12">${p[key]}</div>`
          );
          if (a === -1) list.push(`<div class="col-12">${p[key]}</div>`);
        }
      }

      Swal.fire({
        title:
          '<i class="fas fa-exclamation-circle text-danger"></i> <strong class="text-danger h4">Required</strong>',
        icon: "error",
        html: `<div class="row p-0 m-0">${list.join(" ")}</div>`,
        showCloseButton: true,
        confirmButtonText: "OK!",
      });
    } else if (
      result[1].message !== null &&
      result[1].message !== undefined &&
      result[1].message !== ""
    ) {
      Swal.fire("Oops?", result[1].message, "error");
    } else if (
      result[1].error_description !== null &&
      result[1].error_description !== undefined &&
      result[1].error_description !== ""
    ) {
      Swal.fire("Oops?", result[1].error_description, "error");
    } else {
      Swal.fire("Oops?", "Bad request", "error");
    }
  } catch (err) {}
}

export function ModelStateMessage400(result, setListFun) {
  try {
    /* if (!!result[1]) {
      Swal.fire("Oops?", result[1], "error");
      return false;
    } */
    if (!!result[1]?.message) {
      Swal.fire(
        "Oops?",
        typeof result[1].message !== "string"
          ? result[1].message?.value
          : result[1]?.message,
        "error"
      );
      return false;
    }
    if (!result[1]?.errors) result[1] = JSON.parse(result[1]);
    if (!!result[1]?.errors) {
      var p = result[1]?.errors;
      var list = [];
      var list2 = [];
      for (var key in p) {
        if (p.hasOwnProperty(key)) {
          list2.push({ key: key, value: p[key] });

          var a = list.findIndex(
            // eslint-disable-next-line no-loop-func
            (x) => x === `<div class="col-12">${p[key]}</div>`
          );
          if (a === -1) list.push(`<div class="col-12">${p[key]}</div>`);
        }
      }
      if (!!setListFun) setListFun(list2);

      Swal.fire({
        title:
          '<i class="fas fa-exclamation-circle text-danger"></i> <strong class="text-danger h4">Required</strong>',
        icon: "error",
        html: `<div class="row p-0 m-0">${list.join(" ")}</div>`,
        showCloseButton: true,
        confirmButtonText: "OK!",
      });
    } else if (typeof result[1] === "string") {
      Swal.fire("Oops?", result[1], "error");
    } else {
      Swal.fire("Oops?", "Bad_request", "error");
    }
  } catch (err) {
     if (typeof result[1] === "string") {
      Swal.fire("Oops?", result[1], "error");
    }else {
      Swal.fire("Oops?", "Bad_request", "error");
    }
  }
}
