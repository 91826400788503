import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import ReactSelect from "react-select";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../_metronic/_helpers/GlobalHelpers";
import "react-phone-input-2/lib/style.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
  firstname: "",
  lastname: "",
  phoneNumber: "",
  companyName: "",
  role: "BUSINESSMANAGER",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
  site: "",
  inviteReferral:"",
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    padding: "0.6rem",
    backgroundColor: "#f3f6f9",
    borderColor: "#f3f6f9",
  }),
};

function Registration(props) {
  const { t } = useTranslation();
  const currentlang = localStorage.getItem("i18nConfig");
  const [loading, setLoading] = useState(false);
  const [Steps, setSteps] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  // const { intl } = props;
  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email(translateAuto("Wrong email format"))
      .required(translateAuto("Email is required")),
    password: Yup.string()
      .min(8, translateAuto("Minimum 8 symbols"))
      .max(50, translateAuto("Maximum 50 symbols"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        translateAuto("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
      )
      .required(translateAuto("Password is required")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translateAuto("Passwords must match"))
      .required(translateAuto("Confirm Password is required")),
    firstname: Yup.string().required(translateAuto("First Name is required")),
    lastname: Yup.string().required(translateAuto("Last Name is required")),
    phoneNumber: Yup.string().required(translateAuto("Phone Number is required")),
    companyName: Yup.string().required(translateAuto("Company Name is required")),
    role: Yup.string().required(translateAuto("Role is required")),
    address1: Yup.string().required(translateAuto("Address 1 is required")),
    city: Yup.string().required(translateAuto("City is required")),
    state: Yup.string().required(translateAuto("State is required")),
    zipCode: Yup.string().required(translateAuto("Zip Code is required")),
    country: Yup.string().required(translateAuto("Country is required")),
});

  function checkPassword(password) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  }
  const [IvLink, setIvLink] = useState("");
  function GetInvite() {  
    const currentUrl = window.location.href;
    // Use URLSearchParams to parse the URL and get the value of "ir"
    const urlParams = new URLSearchParams(currentUrl);
    const irValue = urlParams.get('ir');
    setIvLink(irValue)
    console.log(irValue);
    }

  function handleRegister(data) {
    setLoading(true);
    fetch(`${BASE_URL}/Account/Register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": JSON.parse(currentlang)?.selectedLang,
      },
      Allow: "POST",
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return [statusCode, data];
      })
      .then((data) => {
        if (data[0] === 200) {
          setLoading(false);
          props.history.push("/activateaccount");
        } else if (data[0] === 400 && data[1].message) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          setLoading(false);
          for (const [key, value] of Object.entries(data[1].errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  }

  const getCountires = () => {
    fetch(`${BASE_URL}/Generic/Countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          let tt = data[1]?.countries.map((country) => {
            return { value: country.name, label: country.name };
          });
          setCountries(tt);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStates = (code) => {
    console.log("code", code);
    fetch(`${BASE_URL}/Generic/States?name=${code}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          let tt = data[1]?.states.map((state) => {
            return { value: state.name, label: state.name };
          });
          setStates(tt);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useState(() => {
    GetInvite();
    getCountires();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      var url_string = window.location.href;
      if (url_string !== "") {
        var url = new URL(url_string);
        values.roleInviteCode = url.searchParams.get("r");
        values.userInviteCode = url.searchParams.get("c");
      }
      handleRegister(values);
    },
  });
 
    useEffect(()=>{
      if(!!IvLink === true){
        formik.setFieldValue("inviteReferral",IvLink)
      }
    },[IvLink])
  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {Steps === 0 ? (
          <div className="account-settings">
            <div className="text-left mb-10 mb-lg-10">
              <h3 className="font-size-h1">
                {" "}
                {t("Register Business Account!")}
              </h3>
            </div>
            {/* begin: Fullname */}
            <div className="form-group fv-plugins-icon-container row">
              <div class="col-xl-6 coll-lg-6 col-md-12 mb-xl-0 mb-lg-0 mb-md-5 mb-5">
                <input
                  placeholder={t("First name")}
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6)}`}
                  name="firstname"
                  {...formik.getFieldProps("firstname")}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-primary">
                      * {formik.errors.firstname}
                    </div>
                  </div>
                ) : null}
              </div>
              <div class="col-xl-6 coll-lg-6 col-md-12">
                <input
                  placeholder={t("Last name")}
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6)}`}
                  name="lastname"
                  {...formik.getFieldProps("lastname")}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-primary">
                      * {formik.errors.lastname}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* end: Fullname */}

            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={t("Email")}
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6)}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-primary">
                    * {formik.errors.email}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}
            {/* begin: site */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={t("Site")}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6)}`}
                name="site"
                {...formik.getFieldProps("site")}
              />
            </div>
            {/* end: site */}
            {/* begin: Phone */}
            <div className="form-group fv-plugins-icon-container">
              <PhoneInput
                preferredCountries={['us', 'ca', 'ma']}
                inputStyle={{
                  width: "100%",
                  padding: "1.9rem",
                  paddingLeft: "4rem",
                  backgroundColor: "#f3f6f9",
                  borderColor: "#f3f6f9",
                }}
                country={"ca"}
                name="phoneNumber"
                onChange={(value) => formik.setFieldValue("phoneNumber", value)}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-primary">
                    * {formik.errors.phoneNumber}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Phone */}
            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <div className="position-relative">
                <input
                  placeholder={t("Password")}
                  type={showPassword ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-5 px-6)}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <span
                  className="svg-icon svg-icon-md svg-icon-info position-absolute right-0 top-0 pt-5 mr-5 pr-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      showPassword
                        ? "/media/svg/icons/General/Visible.svg"
                        : "/media/svg/icons/General/Hidden.svg"
                    )}
                  ></SVG>
                </span>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-primary">
                    * {formik.errors.password}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={t("Confirm Password")}
                type={showPassword ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6)}`}
                name="confirmPassword"
                {...formik.getFieldProps("confirmPassword")}
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-primary">
                    * {formik.errors.confirmPassword}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}

            {/* begin: Terms and Conditions */}
            {/* <div className="form-group">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  className="m-1"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <span />
                <Link
                  to="/auth/terms"
                  className="ml-1"
                  rel="noopener noreferrer"
                >
                  I agree the Terms & Conditions
                </Link>

              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                </div>
              ) : null}
            </div> */}
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap justify-content-between">
              <Link to="/auth/login">
                <button
                  type="button"
                  className="btn btn-light-info font-weight-bold px-9 py-4 my-3"
                >
                  {t("Cancel")}
                </button>
              </Link>
              <button
                type="button"
                onClick={() => {
                  if (
                    formik.values.firstname === "" ||
                    formik.values.lastname === "" ||
                    formik.values.email === "" ||
                    formik.values.phoneNumber === "" ||
                    formik.values.password === "" ||
                    formik.values.confirmPassword === ""
                  ) {
                    formik.setFieldTouched("firstname", true);
                    formik.setFieldTouched("lastname", true);
                    formik.setFieldTouched("email", true);
                    formik.setFieldTouched("site", true);
                    formik.setFieldTouched("phoneNumber", true);
                    formik.setFieldTouched("password", true);
                    formik.setFieldTouched("confirmPassword", true);
                  } else {
                    if (
                      checkPassword(formik.values.password) &&
                      formik.values.password === formik.values.confirmPassword
                    ) {
                      setSteps(1);
                    }
                  }
                }}
                className="btn btn-info font-weight-bold px-9 py-4 my-3"
              >
                <span> {t("Next")}</span>
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {Steps === 1 ? (
          <div className="company-info">
            <div className="text-left mb-10 mb-lg-10">
              <h3 className="font-size-h1">{t("Company info")}</h3>
            </div>
            {/* begin: Company */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={t("Company name*")}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6)}`}
                name="companyName"
                {...formik.getFieldProps("companyName")}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-primary">
                    {" "}
                    * {formik.errors.companyName}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Company */}

            {/* begin: Address1 */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={t("Address 1*")}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6)}`}
                name="address1"
                {...formik.getFieldProps("address1")}
              />
              {formik.touched.address1 && formik.errors.address1 ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-primary">
                    * {formik.errors.address1}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Address1 */}

            {/* begin: Address2 */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={t("Address 2")}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 `}
                name="address2"
                {...formik.getFieldProps("address2")}
              />
            </div>
            {/* end: Address2 */}

            {/* begin: City & State */}
            <div className="form-group fv-plugins-icon-container row">
              <div class="col-xl-6 coll-lg-6 col-md-12 mb-xl-0 mb-lg-0 mb-md-5 mb-5">
                <ReactSelect
                  styles={customStyles}
                  options={
                    countries.map((item, index) => {
                      return {
                        value: item.value,
                        label: item.label,
                      };
                    }) || []
                  }
                  placeholder="Country*"
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(e) => {
                    formik.setFieldValue("country", e.value);
                    getStates(e.value);
                  }}
                />
                {formik.touched.country && formik.errors.country ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-primary">
                      * {formik.errors.country}
                    </div>
                  </div>
                ) : null}
              </div>
              <div class="col-xl-6 coll-lg-6 col-md-12">
                <input
                  placeholder={t("City*")}
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6)}`}
                  name="city"
                  {...formik.getFieldProps("city")}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-primary">
                      {" "}
                      *{formik.errors.city}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* end: City & State */}

            {/* begin: Zip & Country */}
            <div className="form-group fv-plugins-icon-container row">
              <div class="col-xl-6 coll-lg-6 col-md-12 mb-xl-0 mb-lg-0 mb-md-5 mb-5">
                <ReactSelect
                  styles={customStyles}
                  options={
                    states.map((item, index) => {
                      return {
                        value: item.label,
                        label: item.label,
                      };
                    }) || []
                  }
                  placeholder={t("State*")}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={(e) => {
                    formik.setFieldValue("state", e.value);
                  }}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-primary">
                      * {formik.errors.state}
                    </div>
                  </div>
                ) : null}
              </div>
              <div class="col-xl-6 coll-lg-6 col-md-12">
                <input
                  placeholder={t("Zip*")}
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6)}`}
                  name="zipCode"
                  {...formik.getFieldProps("zipCode")}
                />
                {formik.touched.zipCode && formik.errors.zipCode ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-primary">
                      * {formik.errors.zipCode}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* end: City & State */}
            <div className="separator separator-dashed my-5" />
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap justify-content-between">
              <button
                onClick={() => {
                  setSteps(0);
                }}
                type="button"
                className="btn btn-light-info font-weight-bold px-9 py-4 my-3"
              >
                {t("Previous")}
              </button>
              <button
                type="button"
                onClick={() => {
                  if (
                    formik.values.companyName === "" ||
                    formik.values.address1 === "" ||
                    formik.values.city === "" ||
                    formik.values.state === "" ||
                    formik.values.zipCode === "" ||
                    formik.values.country === ""
                  ) {
                    formik.setFieldTouched("companyName", true);
                    formik.setFieldTouched("address1", true);
                    formik.setFieldTouched("city", true);
                    formik.setFieldTouched("state", true);
                    formik.setFieldTouched("zipCode", true);
                    formik.setFieldTouched("country", true);
                  } else {
                    setSteps(2);
                  }
                }}
                className="btn btn-info font-weight-bold px-9 py-4 my-3"
              >
                <span>Next</span>
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {Steps === 2 ? (
          <div className="review">
            <div className="text-left mb-10 mb-lg-10">
              <h3 className="font-size-h1">Review your Details and Submit</h3>
            </div>
            <h6 className="font-weight-bolder mb-3">Information:</h6>
            <div className="text-dark-50 line-height-lg">
              <div>
                {formik.values.firstname + " " + formik.values.lastname}
              </div>
              <div>{formik.values.companyName}</div>
              <div>{formik.values.email}</div>
              <div>{formik.values.site}</div>
              <div>{formik.values.phoneNumber}</div>
            </div>
            <div className="separator separator-dashed my-5" />
            {/*begin::Section*/}
            <h6 className="font-weight-bolder mb-3">Current Address:</h6>
            <div className="text-dark-50 line-height-lg">
              <div>{formik.values.address1}</div>
              <div>{formik.values.address2 ? formik.values.address2 : ""}</div>
              <div>
                {formik.values.city +
                  ", " +
                  formik.values.state +
                  ", " +
                  formik.values.country +
                  ", " +
                  formik.values.zipCode}
              </div>
            </div>
            {/*end::Section*/}
            <div className="separator separator-dashed my-5" />
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap justify-content-between">
              <button
                onClick={() => {
                  setSteps(1);
                }}
                type="button"
                className="btn btn-light-info font-weight-bold px-9 py-4 my-3"
              >
                Previous
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-info font-weight-bold px-9 py-4 my-3"
              >
                <span>Submit</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
