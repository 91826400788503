import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { AnimateLoading } from "../../_partials/controls";
import { Aside } from "./aside/Aside";
import { QuickUser } from "./extras/offcanvas/QuickUser";
import { ScrollTop } from "./extras/ScrollTop";
import { Footer } from "./footer/Footer";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { useHistory } from "react-router-dom";

export function Layout({ children }) {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [ProfileInitForm , setProfileInitForm] = useState(false);
  const [pic, setPic] = useState(false);
  const history = useHistory();
  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended"),
    };
  }, [uiService]);

  const handleGetUser = () => {
    fetch(`${BASE_URL}/Account/Profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      Allow: "GET",
    })
    .then(async (response) => {
      const statusCode = await response.status;
      const contentType = response.headers.get("content-type");
      if (
        contentType &&  contentType.indexOf("application/json") !== -1
      ) {
        return response.json().then((data) => [statusCode,data]);
      } else {
        return response.text().then((data) => [statusCode,data]);
      }
    })
    .then(data => {
      if(data[0] === 200){
          setProfileInitForm(prev => ({ ...prev, ...data[1]?.profile }));
          setPic(data[1]?.profile?.profilePicture);
      }
      else if (data[0] === 401) {
        history.push("/error/401");
    }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    handleGetUser();
  }, []);

  return layoutProps.selfLayout !== "blank" ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />

      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          <Aside />

          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              <AnimateLoading />
              {layoutProps.subheaderDisplay && <SubHeader />}

              {/*begin::Entry*/}
              {layoutProps.contentExtended && <>{children}</>}

              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser ProfileInitForm= {ProfileInitForm}  pic={pic}/>
      {/* <QuickPanel /> */}
      {/* <QuickNotification /> */}
      <ScrollTop />
      {/* <QuickActions /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
