import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../_metronic/_helpers/GlobalHelpers";
import { useHistory } from "react-router-dom";
import useAuthStore from "./Two-factor/authStore";
import AuthSTockData from "./Two-factor/AuthSTockData";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  console.log(props)
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(translateAuto("Wrong email format"))
      .min(3, translateAuto("Minimum 3 symbols"))
      .max(50, translateAuto("Maximum 50 symbols"))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, translateAuto("Minimum 3 symbols"))
      .max(50, translateAuto("Maximum 50 symbols"))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const history = useHistory();
  const { setLoginData } = useAuthStore();

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Swal.fire("Good job!", "You clicked the button!", "success");
      // return;
      enableLoading();

       setTimeout(() => {
         login(values.email, values.password,null ,null)
           .then((data) => {
            AuthSTockData.getState().setLoginData({
              username: values.email,
              password: values.password,
              code: '',
              isBackup:'',
              
            });
             if (!!data?.data?.data?.token === true && data?.data?.data?.twoFactorEnabled === true) {
               props.login(data?.data?.accessToken);
             } else {
               if (data?.data?.data?.twoFactorReminded) {
                 props.login(data?.data?.accessToken);
               } else {
                 if (data?.data?.data?.twoFactorEnabled === true) {
                   disableLoading();
                   props.history.push("/auth/verification");
                   // props.login(data?.data?.accessToken);
                 } else {
                   setLoginData(data)
                   props.history.push("/auth/twoFactor");
                 }
               }
             }
           })
           .catch(() => {
            console.log("textt")
             disableLoading();
             setSubmitting(false);
             setStatus(
               intl.formatMessage({
                 id: "AUTH.VALIDATION.INVALID_LOGIN",
               })
             );
           });
       }, 1000);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-left mb-10 mb-lg-10">
        <h3 className="font-size-h1">{t("Sign in to your account")}</h3>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t("Email")}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="text-danger">* {formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="position-relative">
            <input
              placeholder={t("Password")}
              type={showPassword ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            <span
              className="svg-icon svg-icon-md svg-icon-info position-absolute right-0 top-0 pt-5 mr-5 pr-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <SVG
                src={toAbsoluteUrl(
                  showPassword
                    ? "/media/svg/icons/General/Visible.svg"
                    : "/media/svg/icons/General/Hidden.svg"
                )}
              ></SVG>
            </span>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="text-danger">* {formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-primary text-hover-primary mb-3 mr-2"
            id="kt_login_forgot"
          >
            {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
            {t("Forgot Password")}
          </Link>
        </div>
        <button
          // id="kt_login_signin_submit"
          type="submit"
          disabled={formik.isSubmitting}
          className={`btn btn-info font-weight-bold px-9 py-4 my-3 w-100`}
        >
          <span>{t("Sign_In")}</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <button
          hidden={true}
          id="kt_login_signin_submit"
          type="submit"
          disabled={formik.isSubmitting}
          className={`btn btn-info font-weight-bold px-9 py-4 my-3 w-100`}
        >
          <span>{t("Sign_In")}</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button> 
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
