import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { translateAuto } from "../../../../_metronic/_helpers/GlobalHelpers";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { t } = useTranslation();
  const currentlang = localStorage.getItem("i18nConfig");
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(translateAuto("Wrong email format"))
      .min(3, translateAuto("Minimum 3 symbols"))
      .max(50, translateAuto("Maximum 50 symbols"))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleForgotPassword = (values) => {
    setLoading(true);
    fetch(`${BASE_URL}/Account/ForgetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": JSON.parse(currentlang)?.selectedLang,
      },
      Allow: "POST",
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: t("success"),
            text: t(
              "We have sent you an email with a link to reset your password"
            ),
          });
          setLoading(false);
          setIsRequested(true);
        } else if (data[0] === 400 && data[1]?.message) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1]?.message,
          });
        } else if (data[0] === 500) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("Something went wrong!. Please try again later"),
          });
          setIsRequested(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{t("Forgotten Password")}</h3>
            <div className="text-muted font-weight-bold">
              {t("Enter your email to reset your password")}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {/* {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )} */}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder={t("Email")}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="text-danger">* {formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-info font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={loading}
              >
                {t("Submit")}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-info font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {t("Cancel")}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
