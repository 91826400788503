import create from 'zustand';

const AuthSTockData = create((set) => ({
  loginData: {
    isBackup: null,
    username: '',
    password: '',
    code: '',
  },
  setLoginData: ({ username, password, code, isBackup }) => set((state) => ({
    loginData: {
      ...state.loginData,
      isBackup: isBackup !== undefined ? isBackup : state.loginData.isBackup,
      username: username !== undefined ? username : state.loginData.username,
      password: password !== undefined ? password : state.loginData.password,
      code: code !== undefined ? code : state.loginData.code,
    },
  })),
}));

export default AuthSTockData;
