import { HubConnectionBuilder } from '@microsoft/signalr';
import clsx from "clsx";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import objectPath from "object-path";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { apiCalll } from "../../../../../_helpers/apiHelpers";
import { DropdownItemToggler, DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import Message from "../notification/message";
import { SearchResult } from "./SearchResult";
import { useCount } from "./contextCount";
import { useTranslation } from "react-i18next";
import NotificationSound from "../../../../../../app/modules/ECommerce/pages/managecommandcenter/sounds/notification-sound.mp3";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const adapter = new LocalStorage("db");
const db = low(adapter);
let controller = new AbortController();
const bgImage = toAbsoluteUrl("/media/bg/bg-4.jpg");

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};
export function SearchDropdown() {
  
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  // Signal R
  const [connection, setConnection] = useState(null);
  const [is12HourFormat, setIs12HourFormat] = useState(false);
  const [chat, setChat] = useState([]);
  const [chatFromApi, setChatFromApi] = useState([]);
  const latestChat = useRef(null);
  const [key, setKey] = useState("Alerts");
  latestChat.current = chat;
  const audioPlayer = useRef(null);

  let timeoutId;

  const clearTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };
  const { t } = useTranslation();

  const handleSearchChange = async (event) => {
    setData(null);
    setSearchValue(event.target.value);
    controller.abort();
    controller = new AbortController();
    await getQuickSearchReselt(event.target.value);
  };
  const { setCount, setReloadRxDrop, setNotifications, setIsSound } = useCount();

  const clear = () => {
    setData(null);
    setSearchValue("");
  };
  function getQuickSearchReselt(params) {
    const signal = controller.signal;
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("user")).token}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      signal: signal,
      redirect: "follow",
    };

    return fetch(
      `${process.env.REACT_APP_BASE_URL}/Search/SmallSearch?query=${params}&entity=global`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
        if (result.length > 0 && result[0] === 200) {
          var datas = result[1];
          setData(datas?.results || []);
          setLoading(false);
        } else if (result.length > 0 && result[0] === 402) {
          Swal.fire({
            title: t("Info!"),
            text: t("You_have_to_subscribe_first"),
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            setLoading(false);
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        setData([]);
        setLoading(false);
        console.log("error", error);
      });
  }
  useEffect(() => {
    return () => {
      clearTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function getNotifications() {
    await apiCalll({
      method: "GET",
      link: `/Notifications?isRead=&pageNumber=${1}&pageSize=${5}&search=&sortField=&sortOrder=desc`,
    }).then((dataResult) => {
      if (!!dataResult && dataResult?.length > 0 && dataResult[0] === 200) {
        setCount(dataResult[1]?.rxDropCount ?? 0);
        var array = dataResult[1]?.notifications || [];
        setChatFromApi([...array]);
        setChat([...array]);
        setNotifications([...array]);
        setIs12HourFormat(dataResult[1]?.is12HourFormat)
      }
    });
  }
  useEffect(() => {
    getNotifications()

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL?.replace("/api","")}/Hubs/chat`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);
  function playAudio() {
    try {
      if (!!audioPlayer.current) {
        audioPlayer.current.play();
      }
    } catch (err) { }
  }

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          var connectionId = connection?.connection?.connectionId;
          if (connectionId) {
            apiCalll({
              method: "PUT",
              link: `/Notifications/SetConnectionId`,
              data: {
                "id": connectionId
              },
            });
          }

          connection.on('ReceiveMessage', message => {
            const updatedChat = [...latestChat.current];
            console.log("🚀 ~ file: UserNotificationsDropdown.js:79 ~ useEffect ~ updatedChat:", updatedChat)
            setCount(message?.rxDropCount);
            setReloadRxDrop(true);
            // updatedChat.push(message);

            setChat([message, ...updatedChat]);
            if(message?.withSound){
              setIsSound(true);
              playAudio();
            }

            setNotifications([message, ...updatedChat]);
          });
        })
        .catch(e => console.log('Connection failed: ', e));
      connection.onreconnected((connectionId) => {
        if (connectionId) {
          apiCalll({
            method: "PUT",
            link: `/Notifications/SetConnectionId`,
            data: {
              "id": connectionId
            },
          });
        }
      });
    }
  }, [connection]);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {!layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {layoutProps.offcanvas &&
        JSON.parse(localStorage.getItem("user"))?.roles.some((v) =>
          [
            "Business Admin",
            "Business User",
            "Business Manager",
            "Business Staff",
            "Business Viewer",
          ].includes(v)
        ) && (
          <Dropdown
            alignRight
            drop="down"
            onToggle={() => {
              setData(null);
              setLoading(false);
              setSearchValue("");
            }}
            id="kt_quick_search_toggle"
            onClick={() => {
              if (document.getElementById("GlobalSearchInput")) {
                document.getElementById("GlobalSearchInput").focus();
              }
            }}
          >
            <Dropdown.Toggle as={DropdownTopbarItemToggler}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="search-panel-tooltip">Quick search</Tooltip>
                }
              >
                <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </div>
              </OverlayTrigger>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
              <div
                id="kt_quick_search_dropdown"
                className={clsx("quick-search quick-search-dropdown", {
                  "quick-search-has-result": data && data.length,
                })}
              >
                <form
                  autoComplete={false}
                  className="quick-search-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (
                      searchValue === "" ||
                      searchValue === null ||
                      searchValue === undefined
                    ) {
                      return;
                    }
                    history.push(`/search?entity=&key=${searchValue}`);
                  }}
                >
                  <div className="input-group">
                    <div className={`input-group-prepend`}>
                      <span
                        className="input-group-text"
                        onClick={() => {
                          history.push(`/search?entity=&key=${searchValue}`);
                        }}
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <input
                      type="text"
                      id="GlobalSearchInput"
                      autoFocus={true}
                      placeholder="Search Anything"
                      value={searchValue}
                      onChange={handleSearchChange}
                      className="form-control"
                    />

                    <div
                      className={`input-group-append ${
                        loading ? "spinner spinner-sm spinner-primary" : ""
                      }")}`}
                    >
                      <span className="input-group-text">
                        <i
                          style={{
                            display:
                              loading && searchValue && searchValue.length > 0
                                ? "none"
                                : "flex",
                          }}
                          onClick={clear}
                          className="quick-search-close ki ki-close icon-sm text-muted"
                        />
                      </span>
                    </div>
                  </div>
                </form>
                <SearchResult
                  data={data}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      {layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-hover-transparent-primary btn-dropdown btn-lg pulse pulse-primary mr-4"
                id="kt_quick_notifications_toggle"
                style={{ width: "50px" }}
              >
                <span
                  className="svg-icon svg-icon-xl"
                  style={{ position: "relative" }}
                >
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Notifications1.svg"
                    )}
                  />
                </span>

                {chat.filter((a) => a.isRead !== true).length > 0 ? (
                  <span
                    className="label label-danger mb-5 mr-2"
                    style={{
                      height: "auto",
                      position: "absolute",
                      right: "-5px",
                      top: "2px",
                      width: "auto",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    {chat.filter((a) => a.isRead !== true)?.length}
                  </span>
                ) : (
                  ""
                )}
                {chat.filter((a) => a.isRead !== true).length > 0 ? (
                  <span className="pulse-ring" />
                ) : (
                  ""
                )}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  <span className="btn btn-text btn-danger btn-sm font-weight-bold btn-font-md ml-2">
                    {chat.filter((a) => a.isRead !== true).length || 0} new
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="justify-content-between nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="Alerts"
                        className={`nav-link show ${
                          key === "Alerts" ? "active" : ""
                        }`}
                      >
                        Events
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item" as="li">
                      <button
                        className={`btn btn-sm btn-light-danger mt-2`}
                        onClick={ async (e) => {
                          e.preventDefault();
                          await apiCalll({
                            method: "PUT",
                            link: `/Notifications/MarkAllAsRead`,
                          })
                            .then((res) => {
                              if (res.length > 0 && res[0] === 200) {
                                getNotifications();
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        }}
                      >
                        Mark all as read
                      </button>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7 text-center text-muted"
                        style={{
                          maxHeight: chat.length === 1 ? "100px" : "300px",
                          position: "relative",
                        }}
                      >
                        {chat.length > 0 ? (
                          <Fragment>
                            {chat.map((m, i) => (
                              <Message
                                key={Date.now() * Math.random()}
                                id={m.id}
                                index={i}
                                title={m.title}
                                date={m.date}
                                icon={m.icon}
                                isRead={m.isRead}
                                link={m.link}
                                getNotificqtions={getNotifications}
                                message={m.text}
                                is12HourFormat={is12HourFormat}
                              />
                            ))}
                          </Fragment>
                        ) : (
                          <h4>No results found.</h4>
                        )}
                      </PerfectScrollbar>
                      <div className="d-flex flex-center pt-7">
                        <Link
                          to="/notifications"
                          className="btn btn-light-primary font-weight-bold text-center"
                        >
                          See All
                        </Link>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <audio ref={audioPlayer} src={NotificationSound} />
    </>
  );
}
