import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { AppString } from "./Const";
import { myFirestore } from "./firebase";
const adapter = new LocalStorage("db");
const db = low(adapter);


async function fetchDataToggle(id, data) {
  try {
      myFirestore
        .collection(AppString.NODE_NOTIFICATIONS)
        .doc(id)
        .set(data)
        .then((data) => {
          console.log('%cfirebaseHelper.js line:16 data', 'color: #007acc;', data);
        });

  } catch (err) {
    console.error("err", err);
  }
}
export {
  fetchDataToggle,
};
