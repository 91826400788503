import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Activationemail() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  var url = new URL(window.location.href);
  var code = url.searchParams.get("code");
  const [accountverified, setaccountverified] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    console.log(code)
    sendCodeFunction(code);
  }, [code]);

  function sendCodeFunction(params) {
    if (params !== null && params !== "" && params !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        Code: params,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BASE_URL}/Account/ConfirmEmail`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setaccountverified(true);
        })
        .catch((error) => console.log("error", error));
    }
  }

  return (
    <div className="login login-3 login-signin-on d-flex flex-row-fluid" id="">
      <div
        className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
        style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
      >
        <div className="col-xl-6 col-lg-6 col-md-10 col-12  p-7 position-relative overflow-hidden">
          {/*begin::Login Header*/}
          <div className="d-flex flex-center mb-15">
            <span>
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/logos/Full-Color_-RGB_Vertical.svg"
                )}
              />
            </span>
          </div>
          {/*end::Login Header*/}
          {/*begin::Login Sign in form*/}
          <div className="card card-custom shadow">
            <div className="card-header card-header-tabs-line">
              <div className="d-flex flex-center flex-column mt-7 mx-auto">
                {!accountverified ? (
                  <>
                    <h2 className="font-size-h1 mb-5 font-weight-bolder">
                      {t("Verify your email")}
                    </h2>
                  </>
                ) : (
                  <>
                    <h2 className="font-size-h1 mb-5 font-weight-bolder">
                       {t("Welcome To Rxpress")}
                    </h2>
                  </>
                )}
              </div>
            </div>
            <div className="card-body">
              <div className="login-signin">
                <div className="text-center">
                  {!accountverified ? (
                    <>
                      <div className="font-size-h3 text-muted font-weight-bold">
                        {t("We have sent an email from")}{" "}
                        <span className="text-info text-decoration-none font-weight-bolder line-height-lg my-3">
                        <a href="mailto:support@deliveryease.co" className="text-info" > support@deliveryease.co</a>

                        </span>
                        <br />
                            {t("verify email")}
                        <br />
                        {t("If you didn’t receive any email. Please")}{" "}
                        <span> {t("Contact us")}</span>
                      </div>
                      <div className="text-center my-10">
                        <Link
                          to="/auth/login"
                          className="btn btn-lg btn-info font-weight-bolder"
                        >
                          {t("Log in")}
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="font-size-h3 text-muted font-weight-bold">
                        <span className="text-info text-decoration-none font-weight-bolder line-height-lg">
                        </span>
                        <br />
                            Your account has been created successfully.
                        <br />
                            Please check your email to verify your account.
                      </div>
                      <div className="text-center my-10">
                        <Link
                          to="/auth/login"
                          className="btn btn-lg btn-info font-weight-bolder"
                        >
                          {t("Log In")}
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end::Login*/}
    </div>
  );
}
